@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.Login-page {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  height: 100vh;
  font-family: "Lokem-Medium", sans-serif;
  color: #333;

  h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 300px;

    .input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      label {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }

      input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #f5f7fa;
        font-family: "Lokem-Medium", sans-serif;

        &:focus {
          border-color: #005fcc;
          outline: none;
        }
      }

      .error-message {
        font-family: "Lokem-Medium", sans-serif;
        color: red;
        font-size: 0.875rem;
        margin-top: 5px;
      }
    }

    .login-button {
      font-family: "Lokem-Medium", sans-serif;
      padding: 10px;
      font-size: 0.9rem;
      color: #fff;
      background-color: #cba53c;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #d4b563;
      }
    }
  }
}
