@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.procrast-main {
  .train-your-main-pro {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
  }

  .flex-dir-pro {
    flex-direction: row-reverse;
  }

  .tyt-paras-pro {
    width: 100%;
    display: inline-flex;
  }

  .left-padding-pro {
    padding-left: 50px;
  }

  .secs-tyt-col1-pro {
    width: 70%;
    text-align: left;
  }

  .tyt-text-pro {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
  }

  .margin-top-pro {
    margin-top: 60px;
  }

  .font-style-first-pro {
    font-family: Lokem-Medium;
  }

  .no-left-padding-pro {
    padding-left: 0;
  }

  .secs-tyt-cols-pro {
    width: 100%;
    text-align: left;
  }

  .secs-tyt-col2-pro {
    text-align: left;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .sec1-image-pro {
    width: 270px;
    margin-top: 45px;
    margin-bottom: 30px;
  }

  .tyt-sub-heading-pro {
    font-size: 28px;
    color: #000;
    font-family: "Lokem-Medium";
  }

  .each-para-div-pro {
    padding-bottom: 25px;
  }

  .courses-main-pro {
    background-color: #fff !important;
    margin-top: 40px;
  }

  .tyt-text-blog-pro {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .row-pro {
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin-top: 25px;
  }

  .column {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-pro {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .prev-next-post-pro {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .post-name-pro {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-pro {
    padding-top: 10px;
  }

  .rate-article-text-pro {
    font-size: 17px;
  }

  .rating-pro {
    font-size: 35px;
  }

  .rating-details-text-pro {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-pro {
    margin-top: 25px;
  }

  .social-text-pro {
    font-size: 20px;
  }

  .social-icons-main-pro {
    margin-top: 14px;
  }

  .blog-social-media-icons-pro {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .blog-social-media-icons i {
    width: 20px;
  }

  .fa-pro {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .text-box-pro {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .text-area {
    width: 100%;
    height: 100px;
    padding: 0px;
    border: 1px, 1px, 0px, 0px solid #ccc;
    border-radius: 10px;
    resize: none;
    margin-bottom: -8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: #dee1e5;
    padding-left: 15px;
    padding-top: 15px;
    font-family: Lokem-Medium;
  }

  .row-last-pro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    border: 1px solid grey;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #dee1e5;
    padding-left: 15px;
  }

  .icons-pro {
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .icon1-pro {
    margin-right: 10px;
    color: #007bff;
    cursor: pointer;
  }

  .lokem-button-pro {
    border: none !important;
    border-radius: 5px !important;
  }

  .rating-content-pro {
    font-size: 22px;
    font-family: Lokem-Headings;
    margin-top: 20px;
  }

  .my-rating-main-pro {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .jq-star-pro {
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;
  }

  .jq-star-svg-pro {
    padding-left: 3px;
    width: 100%;
    height: 100%;
  }

  .submitRatingBtn,
  .cancelRatingBtn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    background: #cba53c;
    border: 1px solid #cba53c;
    font-size: 25px;
    cursor: pointer;
    border-radius: 5px;
    font-family: "Lokem-Headings";
    margin-bottom: 15px;
  }

  .side-header-pro {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }

  .comment-text-head-pro {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }

  .comment-icon-pro {
    font-size: 40px;
    margin-left: 15px;
  }

  .hrLine-pro {
    width: 100%;
  }

  .comment-container-pro {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .circle-pro {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #d3b155;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 30px;
    font-family: Lokem-Lighter;
  }

  rating-comment-pro {
    margin-left: 25px;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .comment-text-pro {
    font-size: 20px;
    font-family: "Lokem-Lighter" !important;
  }

  @media (max-width: 995px) {
    .tyt-paras-pro {
      width: 100%;
      display: inline-block;
    }

    .left-padding-pro {
      padding-left: 0;
    }

    .secs-tyt-col1-pro {
      width: 100%;
      text-align: center;
    }

    .tyt-text-pro {
      text-align: center;
      font-size: 17px;
    }

    .secs-tyt-col2-pro {
      padding-left: 0;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      display: inline-block;
      width: 100%;
    }

    .tyt-sub-heading-pro {
      font-size: 25px;
      color: #000;
      font-family: "Lokem-Medium";
    }

    .row-pro {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column-pro {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }

    .text-area-pro {
      width: 100%;
      height: 100px;
      padding: 0px;
      // border: 1px, 1px, 0px, 0px solid #ccc;
      border-radius: 10px;
      resize: none;
      margin-bottom: -8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-color: #dee1e5;
      padding-left: 0px;
      padding-top: 15px;
      font-family: Lokem-Medium;
    }

    .row-last-pro {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0px;
      border: 1px solid grey;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-color: #dee1e5;
      padding-left: 0px;
    }

    .comment-container-pro {
      display: block;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .circle-main-pro {
      display: inline-block;
      margin-bottom: 10px;
    }

    .circle-pro {
      margin-left: 0;
    }

    .rating-comment-pro {
      margin-left: 0;
      display: inline-block;
      align-items: center;
      margin-right: 0;
      width: 100%;
      margin-bottom: 10px;
    }

    .comment-text-pro {
      margin-left: 0px;
      font-size: 20px;
      width: 100%;
      font-family: "Lokem-Lighter" !important;
    }
  }
}
