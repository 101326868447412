@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.blogs-main {
  .blog-welcome-text {
    padding-bottom: 35px;
    padding-top: 12px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .our-3cs-image-blog {
    border-radius: 50%;
    width: 310px;
    height: 310px;
    object-fit: cover;
    margin-top: -2%;
    margin-bottom: -6%;
    margin-left: -6%;
    object-fit: cover;
  }
  .left-image {
    flex-direction: row;

    .scrumEvent-text-blog {
      text-align: left;
    }
  }

  .right-image {
    flex-direction: row-reverse;

    .scrumEvent-text-blog {
      text-align: left;
    }
  }
  .train-your-main {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
  }

  .bottom-space {
    margin-bottom: 100px;
  }
  .scrumEvents-blog {
    width: 90%;
    background-color: #f1f1f0;
    display: inline-flex;
    text-align: left;
    transition: transform 0.3s ease;
    border-radius: 20px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.09);
      background-color: #e8d49c;
    }
  }
  .scrumEvent-text-blog {
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .padding-top-bot {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
  }
  .tyt-sub-heading {
    font-size: 30px;
    color: #000;
    font-family: "Lokem-Lighter";
    padding-bottom: 15px;
  }
  .each-para-div {
    padding-bottom: 20px;
    min-height: 100px;
  }
  .tyt-text {
    font-family: Lokem-Lighter;
    font-size: 20px;
  }
  .date-read-button {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .min-date-color {
    color: #000;
    margin-bottom: 5px;
    font-family: Lokem-Medium;
  }
  .date-color1 {
    color: #d3b155;
    margin-bottom: 0px;
    font-family: "Lokem-Headings";
  }
  .lokem-button {
    border: none !important;
    border-radius: 5px !important;
  }
  .button-blog1 {
    background: #d3b155;
    width: 130px;
    border: none;
    cursor: pointer;
    padding-bottom: 10px;
    border-radius: 10px;
    font-size: 13px;
    color: white;
    padding-top: 10px;
  }
  @media (max-width: 995px) {
    .bottom-space {
      margin-bottom: 0px !important;
    }
    .scrumEvents-blog {
      width: 100%;
      background-color: #f1f1f0;
      display: inline-block;
      text-align: center;
      max-height: 230px;
      transition: transform 0.3s ease;
      border-radius: 0px;
      margin-bottom: 100px;
      margin-top: 100px;
      max-height: initial;
    }
    .our-3cs-image-blog {
      border-radius: 50%;
      width: 280px;
      height: 280px;
      border: none;
      margin-top: -100px;
      object-fit: cover;
      margin-left: 0px;
      margin-right: 0px;
    }
    .padding-top-bot {
      padding-top: 20px !important;
      padding-bottom: 10px !important;
    }
    .date-read-button {
      display: block;
      justify-content: space-between;
      margin-top: 10px;
    }
    .min-date-color {
      color: #000;
      margin-bottom: 10px !important;
      font-family: Lokem-Medium;
    }
    .blog-welcome-text {
      padding-bottom: 35px;
      padding-top: 35px;
      text-align: center;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .left-image {
      .scrumEvent-text-blog {
        text-align: center;
      }
    }

    .right-image {
      .scrumEvent-text-blog {
        text-align: center;
      }
    }
  }
}
