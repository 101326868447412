//   /* Impact section */

@font-face {
  font-family: "Pristina"; /* Name to be used in CSS */
  src: url("../fonts/Pristina-font.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

// Section 6 //

.impact-section {
  height: 90vh;
  background-image: url("../images/charity-img.jpeg");
  background-size: cover;
  background-position: center;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;

  p {
    font-size: 1.6rem;
    margin: 2rem;
    font-family: Lokem-Lighter, sans-serif;
    line-height: 1.5;
    font-weight: 700;
  }
  .impact-content {
    max-width: 1200px;

    .highlight {
      font-family: Pristina, sans-serif;
      font-size: 2.5rem;
      font-weight: 700;
    }
  }
}

// Media Query for max-width: 768px
@media (max-width: 768px) {
  // Impact section //
  .impact-section {
    height: 100vh;
    margin-top: -7px;
    padding: 1rem;

    p {
      font-family: Lokem-Lighter, sans-serif;
      font-size: 1.2rem;
      margin: 1rem;
      line-height: 2.3rem;
    }

    .impact-content {
      width: 100vw;

      .highlight {
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
  }
}
