@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}
  
@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}
  
@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
  
.admin-page {
    display: flex;
    height: 100vh;

    .sidebar {
        width: 200px;
        background-color: #8d773b;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px;
        position: fixed;

        .logo-section {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 12px;

        .logo-img {
            height: 28px;
            min-width: 60px;
            cursor: pointer;
            object-fit: scale-down;
            margin-left: -20px;
        }

        i {
            font-size: 30px;
        }

        .logo-text {
            font-family: 'Lokem-Medium';
            font-size: 24px;
        }
        }

        .sidebar-links {
        list-style: none;
        padding: 0;
        margin-top: 30px;

        li {
            display: flex;
            align-items: center;
            gap: 26px; 
            padding: 15px 0; 
            font-size: 15px;
            font-family: 'lokem-Medium';
            color: #fff;
            cursor: pointer;
            transition: all 0.4s ease;

            
            i {
            font-size: 16px; 
            margin-left: -20px; 
            }
        }
        }

        .logout-btn {
            background-color: #8d773b;
            color: #fff;
            font-size: 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 28px;
            margin-top: 200px;
            font-family: 'Lokem-Medium';

            i {
                font-size: 18px;
                margin-left: -10px;
            }
        }
    }

    .welcome-section {
        flex: 1;
        background-color: #f5f5f5; 
        flex-direction: column;
        gap: 20px;
        transition: transform 0.4s ease; 
        z-index: 10;
        transform: translateX(60px);

        &.slide-left {
            transform: translateX(240px); 
        }

        .welcome-content {
            display: flex;
            flex-direction: row; 
            align-items: center;
            justify-content: flex-start; 
            gap: 10px; 
            font-size: 24px;
            font-family: 'lokem-medium';
            color: #333;
            background-color: #ffffff; 
            width: 100%; 
            padding: 20px;
        }

        .fa-solid:before {
        font-size: 35px;
        }
    }
}
