@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");  
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}


.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 20px;
  z-index: 1000;

  p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #000000;
    font-family: 'Lokem-Headings';
    margin-bottom: 10px;

    strong u {
      color: #000000;
      cursor: pointer;
      font-family: 'Lokem-Headings';
    }
  }

  .cookie-buttons {
      display: flex;
      justify-content: center;
      margin-top: 35px;
      gap: 50px; 
      text-align: center;

      button {
          width: 150px;
          font-family: Lokem-Headings;
          font-size: 20px;
          border-radius: 6px;
          background: #cba53c;
          cursor: pointer;
          border: 1px solid;
          color: #fff; 
          margin-top: 2px;
          margin-bottom: -20px;
          padding-bottom: 8px;
          padding-top: 8px;
      
          
  
          &.accept-btn {
          background-color: #cba53c;
          }
  
          &.personalise-btn {
          background-color: #cba53c;
          }
      }
  }
}

.cookie-banner.expanded {
  height: 70vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: auto;
}

.privacy-word {
  margin-left: 4px;
  font-family:'Lokem-Lighter';
}

.cookie-banner .expanded-content {
  width: 62%;
  border: 2px solid #8496a7;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  text-align: center;

  h2 {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 45px;
    color: #cba53c;
    font-family: 'Lokem-Headings';
  }

  p {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Lokem-Lighter';
    color: black;   

    u {
      cursor: pointer;
    }
  }

  .cookie-options {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    margin-bottom: 20px;
    overflow-y: auto; 
    max-height: 300px;

    .cookie-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
  
        .cookie-toggle {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          flex: 1;
          text-align: left;

          h3 {
              text-align: center;
              font-size: 22px;
              margin-bottom: 5px;
              font-family: 'Lokem-Headings';
          }

          span{
            text-align: center;
            font-size: 22px;
            font-family: 'Lokem-Headings';
          }

          .switch {
            position: relative;
            display: inline-block;
            width: 60px; 
            height: 34px;
            margin-top: 5px;

            input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc; 
              transition: .4s; 
              border-radius: 34px; 
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 26px; 
              width: 26px; 
              left: 4px; 
              bottom: 4px; 
              background-color: white;
              border-radius: 50%; 
              transition: .4s; 
            }

            input:checked + .slider {
              background-color: #7ed956; 
            }

            input:checked + .slider:before {
              transform: translateX(26px); 
            }
          }
        }
      
        .cookie-text {
            flex: 2;
            padding-left: 15px;
            text-align: left;

            p {
                font-size: 20px;
                color: black;
                font-family: 'Lokem-Lighter';
            }
        }
    }

    .button-common {
      width: 140px;
      font-family: Arial;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      color: #fff;
      margin-right: 80px;
    }

    
    .save-btn {
      background: #8194a5; 
    }

    
    .accept-btn {
      background: #cba53c; 
    }
  }
}