@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.userStory-main {
  .train-your-main-user {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
  }

  .first-para-user {
    padding-top: 40px !important;
    font-family: Lokem-Medium !important;
    margin-left: 0px !important;
  }

  .each-para-div-user {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .tyt-text-user {
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
  }

  .tyt-paras-user {
    width: 100%;
    display: inline-flex;
    padding-top: 30px;
  }

  .secs-tyt-col1-user {
    width: 70%;
    text-align: left;
  }

  .tyt-text-user {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
    margin-left: 15px;
  }

  .tyt-sub-heading-user {
    font-size: 30px;
    color: #000;
    font-family: "Lokem-Medium";
  }

  .secs-tyt-col2-user {
    text-align: left;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .our-3cs-image1-user {
    height: 230px;
    border: none;
    object-fit: cover;
    border-radius: 20px;
  }

  .flex-dir-user {
    flex-direction: row-reverse;
  }

  .margin-bottum-user {
    padding-bottom: 0px !important;
  }

  .para-margin-user {
    padding-bottom: 20px;
    padding-top: 0px !important;
  }

  .secs-tyt-col2-padding-user {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    display: flex;
    width: 30%;
  }

  .sec1-image-user {
    width: 100%;
    height: 280px;
    border: none;
    object-fit: cover;
    border-radius: 20px;
  }

  .sec2-image-user {
    width: 100%;
    height: 200px;
    border: none;
    object-fit: cover;
    border-radius: 20px;
  }

  .tyt-text-blog-user {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .row-user {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .column-user {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-user {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .post-name-user {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-user {
    padding-top: 10px;
  }

  .rate-article-text-user {
    font-size: 17px;
  }

  .rating-user {
    font-size: 35px;
  }

  .rating-details-text-user {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-user {
    margin-top: 25px;
  }

  .social-text-user {
    font-size: 20px;
  }

  .social-icons-main-user {
    margin-top: 14px;
  }

  .blog-social-media-icons-user {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .blog-social-media-icons-user i {
    width: 20px;
  }

  .fa-user {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .prev-next-post-user {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .side-header-user {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }

  .comment-text-head-user {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }

  .comment-icon-user {
    font-size: 40px;
    margin-left: 15px;
  }

  .hrLine-user {
    width: 100%;
  }

  @media (max-width: 995px) {
    .first-para-user {
      font-size: 18px !important;
      margin-left: 0px !important;
    }

    .tyt-text-user {
      text-align: center;
      font-size: 17px;
      margin-left: 0;
    }

    .tyt-paras-user {
      width: 100%;
      display: inline-block;
    }

    .secs-tyt-col1-user {
      width: 100%;
      text-align: center;
    }

    .tyt-sub-heading-user {
      font-size: 25px;
      color: #000;
      font-family: "Lokem-Medium";
    }

    .secs-tyt-col2-user {
      padding-left: 0;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      display: inline-block;
      width: 100%;
    }

    .our-3cs-image1-user {
      height: 230px;
      border: none;
      object-fit: initial;
      width: 350px;
    }

    .secs-tyt-col2-padding-user {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      display: inline-block;
      width: 100%;
    }

    .sec2-image-user {
      height: 300px;
      border: none;
      object-fit: initial;
      width: 350px;
    }

    .row-user {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column-user {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }
  }
}
