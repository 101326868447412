@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold";
  src: url("../fonts/Sofia_Pro_Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.scrumMaster-main {
  // Banner Section
  .top-page-sm {
    background: url("../images/courses-main1.png") no-repeat center center;
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-page-container-sm {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 0px;
    color: #fff;
  }
  .lokem-headers-sm {
    font-family: "Lokem-Lighter";
    font-size: 60px;
  }

  .first-page-heading-sm {
    padding-bottom: 0px;
  }
  .banner-heading-sm {
    color: #fff;
  }
  .training-dates-button-section-sm {
    padding-bottom: 10px;
  }
  .book-btn-position-sm {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -42px;
  }
  .book-place-button-sm {
    padding: 18px;
    padding-left: 30px;
    padding-right: 30px;
    background: #cba53c;
    color: #fff;
    font-size: 25px;
    border: none;
    border-radius: 5px;
    font-family: Lokem-Lighter;
    cursor: pointer;
  }

  // Section 2
  .training-overview-main-sm {
    background: #fff;
  }
  .train-your-main-sm {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    /*background-image: url(../media/images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d);*/
    background-repeat: no-repeat;
    background-position: center;
  }
  .max-width-div-sm {
    /* max-width: 1200px; */
    width: 100%;
    display: inline-block;
  }
  .overview-cols-main-sm {
    width: 100%;
    display: inline-flex;
  }
  .overview-col1-sm {
    width: 75%;
    text-align: left;
    padding-top: 25px;
  }
  .tyt-heading-main-sm {
    width: 100%;
    padding-bottom: 25px;
  }
  .tyt-heading-sm {
    text-align: left;
  }
  .tyt-heading-text-sm {
    font-size: 45px;
    font-family: Lokem-Medium;
  }
  .tyt-text-sm {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 25px;
    padding-left: 5px;
  }
  .each-para-div-sm {
    display: inline-flex;
    padding-bottom: 25px;
  }
  .overview-col2-sm {
    float: right;
    padding-left: 30px;
  }
  .course-overview-image1-sm {
    float: left;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
  }
  .course-overview-image2-sm {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    float: left;
    margin-top: 20px;
    object-fit: cover;
  }

  // Section 3
  .tobj-main-sm {
    width: 80%;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #222222;
    color: #fff;
  }

  .tobj-heading-sm {
    text-align: center !important;
    color: #cbb36e !important;
    font-family: Lokem-Medium;
  }

  .tobj-sub-sm {
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 21px;
    font-family: Lokem-Medium;
  }

  .tobj-col-main-sm {
    width: 100%;
  }

  .tobj-row-sm {
    display: inline-flex;
    width: 100%;
    padding-bottom: 40px;
  }

  .tobj-col1-sm {
    width: 44%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .tobj-color-sm {
    margin-bottom: 5px;
  }

  .tyt-sub-heading-sm {
    font-size: 25px;
    color: #fff;
    font-family: "Lokem-Lighter";
  }

  .tobj-img-sm {
    width: 80px;
    height: 90px;
  }
  /* Section 4 */
  .padding-bottom-para-sm {
    padding-bottom: 40px !important;
  }
  .mc-heading-sm {
    padding-bottom: 25px;
    color: #efbd20;
    border: none !important;
    border-bottom: 1px solid #e8cd77;
    padding-top: 10px;
    font-family: Lokem-Medium;
  }
  .items-cols-sm {
    width: 80%;
    display: inline-flex;
    margin-right: 20%;
  }
  .item-col1-sm {
    width: 50%;
  }
  .modules-covered-line-sm {
    padding: 0 !important;
    width: 66%;
    background: #e8cd77;
  }
  .modules-covered-line-sm hr {
    border: 1px solid #e8cd77;
  }
  .item-col2-sm {
    width: 50%;
  }
  .fac-course-sm {
    color: #efbd20;
    padding-top: 60px;
    padding-bottom: 25px;
    font-family: Lokem-Medium;
  }
  .training-dates-main-sm {
    background: url(../images/our3cBG.png),
      linear-gradient(to right, #ebd9a5, #e4cd8d);
    background-position: right;
    width: 260px;
    text-align: center;
    border-radius: 10px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 35px;
    margin-top: 45px;
  }
  .training-dates-text-sm {
    color: #000;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: Lokem-Medium;
    font-size: 30px;
  }
  .location-online-sm {
    padding-bottom: 20px;
  }
  .package-includes-sm {
    padding-bottom: 20px;
  }
  .booknow-style-sm {
    padding-bottom: 45px;
  }
  .lf-use-code-main-sm {
    font-family: Lokem-Lighter;
    font-size: 16px;
    padding-bottom: 5px;
  }
  .lf-coupon-code-sm {
    color: #161411;
    font-family: "Lokem-Headings";
  }

  // Section 5
  .po-journey-main {
    display: flex;
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background: #fff;
    position: relative;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .po-Infograph {
    width: 100%;
  }

  @media (max-width: 995px) {
    // Banner Section
    .top-page-sm {
      background: url("../images/courses-main1.png") no-repeat center center;
      min-height: 390px;
      background-size: cover;
    }
    .first-page-container-sm {
      text-align: center;
      margin-left: 3%;
      margin-right: 3%;
      padding-top: 60px;
      color: #fff;
    }
    .lokem-headers-sm {
      font-family: "Lokem-Lighter";
      font-size: 40px;
    }
    .first-page-heading-sm {
      font-size: 40px;
    }
    .book-btn-position-sm {
      bottom: -30px;
    }
    .items-cols-sm {
      width: 100%;
      display: initial;
      padding: 0;
      margin: 0;
    }
    .item-col1-sm {
      width: 60%;
      margin-right: 20%;
      margin-left: 20%;
    }
    .modules-covered-line-sm {
      padding: 0 !important;
      width: 100%;
      background: #e8cd77 !important;
    }
    .item-col2-sm {
      width: 60%;
      margin-right: 20%;
      margin-left: 20%;
    }
    .book-place-button-sm {
      padding: 8px;
      padding-left: 18px;
      padding-right: 18px;
      background: #cba53c;
      color: #fff;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      font-family: Lokem-Lighter;
    }
    .train-your-main-sm {
      display: inline-block;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: center;
    }
    .max-width-div-sm {
      max-width: 1200px;
      width: 100%;
      display: inline-block;
    }
    .overview-cols-main-sm {
      width: 100%;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
    }
    .overview-col1-sm {
      width: 100%;
      text-align: center;
    }
    .tyt-heading-main-sm {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .tyt-heading-text-sm {
      font-size: 30px;
      font-family: Lokem-Medium;
    }
    .tyt-heading-sm {
      text-align: center;
    }
    .overview-col2-sm {
      width: 100%;
      margin-left: 0;
    }
    .course-overview-image1-sm {
      float: initial;
      width: 300px;
      height: 300px;
      border-radius: 50%;
    }
    .course-overview-image2-sm {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      float: initial;
      margin-top: 20px;
    }
    .tyt-heading-text-sm {
      font-size: 30px;
      font-family: Lokem-Medium;
    }
    .tobj-row-sm {
      display: initial;
      width: 100%;
      padding-bottom: 40px;
      text-align: center;
    }
    .tobj-col1-sm {
      width: 94%;
      margin-left: 3%;
      margin-right: 3%;
    }
    .tyt-sub-heading-sm {
      font-size: 22px;
    }
    .training-overview-main-sm {
      background: #fff;
    }
    .max-width-div-sm {
      max-width: 1200px;
      width: 100%;
      display: inline-block;
    }
    .training-dates-main-sm {
      background: url(../images/our3cBG.png),
        linear-gradient(to right, #ebd9a5, #e4cd8d);
      background-position: right;
      /* background: #fcdf8f; */
      width: auto;
      text-align: center;
      border-radius: 10px;
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 35px;
      margin-top: 15px;
    }
    .po-journey-main-sm {
      display: flex;
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      background: #fff;
      position: relative;
      padding-bottom: 0px !important;
      padding-top: 0px !important;
    }
  }
}
