@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.aceYour-main {
  .lokem-headers {
    font-size: 47px !important;
  }
  .train-your-main-ace {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
  }

  .blog-welcome-text-ace {
    width: 100%;
    text-align: left;
    font-family: Lokem-Medium;
    font-size: 20px;
  }

  .each-para-blog-ace {
    width: 100%;
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
    padding-bottom: 20px;
    padding-top: 10px;
    font-family: "Lokem-Medium";
  }

  .tyt-paras-ace {
    display: inline-flex;
    overflow: hidden;
  }

  .flex-dir-ace {
    flex-direction: row-reverse;
  }

  .secs-tyt-col1-ace {
    width: 60%;
    text-align: left;
    margin-left: 35px;
    font-family: "Lokem-Lighter";
    font-size: 20px !important;
  }

  .tyt-text-ace {
    font-family: "Lokem-Lighter";
    font-size: 20px !important;
    width: 100%;
  }

  .padding-top-para-ace {
    padding-bottom: 20px;
    font-family: "lokem-lighter";
  }

  .bold-font-ace {
    font-size: 20px;
    font-family: lokem-medium;
  }

  .secs-tyt-col2-padding-ace {
    width: 40%;
  }

  .sec1-image-ace {
    width: 100%;
  }

  .tyt-paras-star-ace {
    width: 100%;
    height: auto;
    display: flex;
  }

  .tyt-text-star-ace {
    float: left;
    font-family: "Lokem-Lighter";
    font-size: 20px;
    text-align: left;
    width: 68%;
    margin-right: 35px;
  }

  .padding-top-para-star-ace {
    margin-bottom: 20px;
  }

  .padding-top-para-star1-ace {
    margin-bottom: 0px;
  }

  .image-blog-ace {
    /* margin-top: 55px; */
    object-fit: cover;
    margin-left: 0px;
    margin-right: 0px;
    float: right;
    width: 30%;
  }

  .sec3-image-ace {
    width: 100%;
    object-fit: cover;
  }

  .sec2-image-ace {
    width: 100%;
    object-fit: cover;
  }

  .tyt-sub-heading-ace {
    text-align: left;
    font-size: 30px;
    font-family: "Lokem-Medium";
    margin-bottom: 10px;
  }

  .para-divs-ace {
    width: 100%;
    margin-bottom: 20px;
    font-family: "lokem-lighter";
    text-align: left;
    font-size: 20px;
  }

  .bold-font-ace {
    font-size: 20px;
    font-family: lokem-medium;
  }

  .text-para-ace {
    margin-top: 10px;
  }

  .star-list-ace {
    text-align: left;
    font-family: "lokem-lighter";
    font-size: 20px;
    margin-top: 0px;
  }

  .tyt-text-blog-ace {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .row-ace {
    display: flex;
    text-align: center;
    justify-content: space-between;
  }

  .column-ace {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-ace {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .post-name-ace {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-ace {
    padding-top: 10px;
  }

  .rate-article-text-ace {
    font-size: 17px;
  }

  .rating-ace {
    font-size: 35px;
  }

  .rating-details-text-ace {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-ace {
    margin-top: 25px;
  }

  .social-text-ace {
    font-size: 20px;
  }

  .social-icons-main-ace {
    margin-top: 14px;
  }

  .blog-social-media-icons-ace {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .blog-social-media-icons-ace i {
    width: 20px;
  }

  .fa-ace {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .prev-next-post-ace {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .side-header-ace {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }

  .comment-text-head-ace {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }

  .comment-icon-ace {
    font-size: 40px;
    margin-left: 15px;
  }

  .hrLine-ace {
    width: 100%;
  }

  @media (max-width: 995px) {
    .train-your-main-ace {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 0px;
      padding-top: 0px;
    }

    .blog-welcome-text-ace {
      width: 100%;
      text-align: left;
      font-size: 18px;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .each-para-blog-ace {
      text-align: left;
      font-size: 18px;
      padding-top: 0px;
    }

    .tyt-paras-ace {
      width: 100%;
      display: inline-block;
    }

    .secs-tyt-col1-ace {
      width: 100%;
      text-align: left;
      padding-bottom: 0px;
      margin-left: 0px;
    }

    .tyt-text-ace {
      font-family: "Lokem-Lighter";
      font-size: 20px;
      width: 100%;
    }

    .padding-top-para-ace {
      padding-bottom: 10px;
      font-family: "lokem-lighter";
    }

    .secs-tyt-col2-padding-ace {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 0px;
    }

    .image-section-ace {
      height: 266px;
      margin-bottom: 20px;
    }

    .sec1-image-ace {
      height: 295px;
    }

    .tyt-paras-star-ace {
      width: 100%;
      height: auto;
      display: block;
    }

    .tyt-text-star-ace {
      width: auto;
      margin-right: 0px;
    }

    .image-blog-ace {
      width: 100%;
      margin-top: 10px;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
    }

    .sec3-image-ace {
      width: 100%;
      margin-bottom: 15px;
      height: 266px;
      margin-top: 15px;
    }

    .secs-tyt-col2-padding-ace {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 0px;
    }

    .tyt-sub-heading-ace {
      text-align: left;
      font-size: 25px;
      font-family: "Lokem-Medium";
      margin-bottom: 10px;
    }

    .sec2-image-ace {
      width: 100%;
      height: 266px;
    }

    .para-divs-ace {
      width: 100%;
      margin-bottom: 10px;
      font-family: "lokem-lighter";
      text-align: left;
      font-size: 20px;
    }

    .row-ace {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column-ace {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }
  }
}
