@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Pristina"; /* Name to be used in CSS */
  src: url("../fonts/Pristina-font.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}
body {
  margin: 0;
  font-family: Lokem-Headings !important;
  overflow-x: hidden !important;
  line-height: initial !important;
  color: #000 !important;
  background-color: #fff !important;
}
.homePage-main {
  .reqCBMain {
    padding-top: 60px;
  }
  .reqCBHeading {
    text-align: center;
    font-size: 32px;
    color: white;
    margin-bottom: 10px;
    font-family: Lokem-Medium;
  }
  .top-section-main2 {
    background: url(../images/first-page.png);
    /* min-height: 890px; */
    height: 650px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 1.42857143;
  }
  .top-page-container2 {
    text-align: center;
    font-size: 50px;
    margin-left: 23%;
    margin-right: 23%;
    padding-top: 150px;
    color: #fff;
  }
  .top-page-heading2 {
    font-family: Lokem-Lighter;
    font-size: 30px;
  }
  .top-page-text2 {
    font-family: Lokem-Lighter;
    padding-top: 30px;
    font-size: 25px;
  }

  .course-main1 {
    width: 84% !important;
    padding-left: 8% !important;
    padding-right: 8% !important;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background: white;
  }
  .mainDiv1 {
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
  }
  .course-color21 {
    width: 50%;
    background-color: white;
    font-family: Lokem-Lighter;
  }
  .course-color22 {
    width: 50%;
    background-color: white;
    font-family: Lokem-Lighter;
  }
  .lf-coupon-code {
    color: #4d4735;
    font-family: "Lokem-Headings";
  }
  .course-style {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
  .course-heading {
    font-size: 27px;
    font-family: Lokem-Medium;
    padding-bottom: 0px !important;
  }
  .path-img {
    width: 100%;
  }
  .course-para {
    font-size: 19px;
    font-family: "Lokem-Lighter";
  }
  .find-more-link {
    cursor: pointer;
    text-decoration: underline;
    color: #cba53c;
  }
  .pathway-btn-container {
    margin-top: 30px;
  }
  .lf-use-code-main {
    font-family: Lokem-Lighter;
    font-size: 16px;
    padding-bottom: 5px;
  }
  .lokem-button {
    border: none !important;
    border-radius: 5px !important;
  }
  .book-place-button {
    padding: 8px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    background: #cba53c;
    color: #fff;
    font-size: 24px;
    border: none;
    border-radius: 5px;
    font-family: Lokem-Lighter;
    margin-bottom: 25px;
  }
  .pathway-btn {
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background: #cba53c;
    color: #fff;
    /* font-size: 18px;*/
    border: none;
    border-radius: 5px;
    font-family: Lokem-Lighter;
    cursor: pointer;
  }

  .section-with-gradient {
    color: #fff;
    background-color: #19191a;
    background-image: linear-gradient(
      to right,
      #19191a,
      #2f2f2f,
      #727171,
      #2f2f2f,
      #19191a
    );
    padding-bottom: 50px;
    padding-top: 0px;
  }
  .row {
    display: inline-block;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-family: Lokem-Lighter;
    margin-top: 50px;
  }
  .section-img {
    width: 80% !important;
  }
  .section {
    float: left;
    width: 33%;
    text-align: center;
  }
  .section-title {
    font-size: 20px;
    font-family: Lokem-Headings;
    padding-bottom: 10px;
  }
  .section-info {
    padding-bottom: 10px;
    margin-left: 8%;
    margin-right: 8%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }
  .pressPlayText {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    font-size: 32px;
  }

  .testimonials {
    background-image: url(".././images/voice-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  .testimonial-image {
    border-radius: 50%;
    width: 280px;
    height: 280px;
    margin: 0px;
    padding: 0px;
  }
  .open-quote {
    width: 65px;
    align-self: flex-start;
  }
  .homepage-testimonial {
    float: left;
    text-align: center;
    width: 33%;
    color: #fff;
    font-family: Lokem-Lighter;
    font-style: italic;
    font-size: 25px;
    margin-top: 3%;
    margin-bottom: 35px;
  }
  .tm-audio-container {
    padding-top: 10px;
  }
  .tm-audio-author {
    font-family: Lokem-Headings;
    font-style: normal;
    font-size: 22px;
  }

  .left-arrow-container,
  .right-arrow-container {
    cursor: pointer;
  }

  .tm-section {
    background-image: url(".././images/voice-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 800px;
  }
  .tm-container {
    width: 100%;
    height: auto !important;
    display: inline-flex;
  }
  .active-slide {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  .tm-img-div {
    width: 30%;
    height: 100%;
    text-align: right;
    float: left;
    padding-top: 180px;
  }
  .tm-img {
    border-radius: 50%;
    width: 250px;
    height: 250px;
  }
  .tm-content-div {
    width: 70%;
    height: 100%;
    font-family: Lokem-Lighter;
    padding-left: 50px;
    padding-right: 15%;
    padding-top: 120px;
    vertical-align: top;
    float: left;
    font-size: 16px;
  }
  .tm-content-div {
    width: 70%;
    height: 100%;
    font-family: Lokem-Lighter;
    padding-left: 50px;
    padding-right: 15%;
    padding-top: 120px;
    vertical-align: top;
    float: left;
    font-size: 16px;
  }
  .quote-img {
    width: 65px;
  }
  .tm-content-container {
    color: #fff;
  }
  .tm-author {
    color: #f3bf30;
  }
  .quote-img-container-bottom {
    text-align: right;
    padding-right: 40px;
  }
  .hide-slide {
    display: none !important;
  }

  .hide {
    display: none;
  }
  .tm-audio-play-content {
    text-align: center;
    font-size: 25px;
    color: #fff;
    font-family: "Lokem-Lighter";
  }
  .git-content {
    text-align: center;
    font-size: 20px;
    margin-bottom: 35px;
    font-family: "Lokem-Lighter";
  }
  .git-heading {
    text-align: center;
    font-size: 40px;
    font-family: Lokem-Headings;
  }

  p {
    font-family: "Lokem-Lighter";
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  /* Nav Icons */
  .nav-icons {
    position: absolute; /* Position within the section */
    bottom: 20px; /* Place at the bottom of the section */
    width: 100%; /* Stretch across the section */
    display: flex;
    justify-content: space-between; /* Arrows on sides */
    align-items: center; /* Vertically center arrows */
    line-height: initial;
    font-size: initial;
  }

  /* Centered Carousel Indicators */
  .carousel-indicators {
    position: absolute;
    bottom: 0px; /* Slightly above arrows */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    display: flex;
    gap: 8px; /* Space between indicators */
  }

  .carousel-indicator {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .carousel-indicator.active {
    background-color: rgb(222, 171, 32); /* Highlight active indicator */
  }
  .testimonial-author {
    color: #f3bf30;

    margin: 0;
    padding: 0;
    text-align: left; /* Optional alignment */
    font-size: 16px; /* Adjust size as needed */
  }

  /* Arrows */

  .arrow-img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s;
  }

  .left-arrow-container {
    position: absolute;
    left: 0px; /* Offset from the left edge */
    cursor: pointer;
    text-align: center;
    margin-bottom: 8px;
  }

  .right-arrow-container {
    position: absolute;
    right: 0px; /* Offset from the right edge */
    cursor: pointer;
    margin-bottom: 8px;
  }
  .arrow-img {
    width: 50px;
  }
  .dots-container {
    text-align: center;
  }
  .arrow-img:hover {
    cursor: pointer;
  }

  .marquee-container-homepage {
    background: #fff;
    width: 98%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 1%;
    padding-right: 1%;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    font-family: "Lokem-Lighter";

    /*text-align: center;
overflow: hidden;
width: 100%;
height: 250px;
position: relative;*/

    .heading {
      font-family: "Lokem-Lighter", sans-serif;
      font-size: 1.9rem;
      margin: 2rem;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 0rem;
        margin-top: 2rem;
      }
    }

    .marquee-wrapper {
      padding-top: 40px;
      overflow: hidden;
    }

    .marquee-homepage {
      display: flex;
      animation: marqueeAnimation 5s linear infinite;
      will-change: transform;
      transform: translateY(0);

      &:hover {
        animation-play-state: paused;
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 1rem;

        img {
          height: 120px;
          width: 140px;
          object-fit: contain;
        }
      }
    }
  }

  @keyframes marqueeAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  // Section 7   //
  .get-in-touch {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 60px;
    color: black;
    background: #f3f3f2;

    h1 {
      font-family: "Lokem-Headings";
      font-size: 40px;
      margin: 0px;
      padding: 0;
    }

    p {
      text-align: center;
      font-size: 20px;
      margin-bottom: 35px;
    }
  }
  .phone-email-content {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-top: 35px;
  }
  .git-phone-email-container {
    float: left;
    width: 50%;
  }
  .git-phone-content {
    width: fit-content;
    float: right;
  }
  .git-phone-email-container img {
    max-width: 25px;
    float: left;
    margin: 0 10px 0 0;
  }
  .git-details-font {
    font-family: Lokem-Headings;
    font-size: 20px;
    text-decoration: none;
  }
  .git-email-content {
    width: fit-content;
    padding-left: 3%;
  }

  .contactMsg {
    margin-top: 12px;
    font-family: "Lokem-Headings";
    width: 84%;
    color: #cba53c;
    font-size: 18px;
    text-align: left;
  }
  .contactSelect {
    width: 100%;
    height: 32px;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #fff;
    padding-left: 6px;
    border-radius: 3px;
  }

  .error-message {
    color: #df1b41;
    font-weight: bold;
    font-size: 15px;
    font-family: Lokem-Headings;
    text-align: left;
    margin-top: 5px;
    margin-left: 2px;
  }

  .charity-page {
    background-image: url("../images/charity-img.jpeg");
    min-height: 650px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  .charity-page-container {
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 0;
    color: #fff;
    width: 90%;
  }
  .charity-banner-heading {
    font-family: "Lokem-Lighter";
    font-size: 28px;
    color: #fff;
    line-height: 44px;
  }
  .charity-page-heading {
    padding-bottom: 0px;
  }
  .p1 {
    font-size: 26px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-family: "Lokem-Lighter";
  }
  .p2 {
    font-size: 26px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    font-family: "Lokem-Lighter";
    margin-top: 50px;
  }
  .charity-font {
    font-size: 40px;
    /*font-family: "Charity-Font";*/
    font-family: "Pristina";
    font-weight: 300;
  }

  @media (max-width: 995px) {
    .top-section-main2 {
      background: url(../images/first-page.png);
      height: auto;
    }
    .error-message {
      color: #df1b41;
      font-weight: bold;
      font-size: 15px;
      font-family: Lokem-Headings;
      text-align: left !important;
      margin-top: 5px;
      margin-left: 2px;
    }
    .top-page-container2 {
      text-align: center;
      font-size: 20px;
      margin-left: 3%;
      margin-right: 3%;
      padding-top: 50px;
      color: #fff;
    }
    .top-page-heading2 {
      font-size: 25px;
    }
    .top-page-text2 {
      font-size: 22px;
    }

    .phone-email-content {
      display: inline-block;
      text-align: center;
      width: 100%;
      padding-top: 0px;
    }
    .git-phone-email-container {
      margin-top: 15px;
      text-align: center;
      width: auto;
      float: initial;
    }
    .git-phone-content {
      text-align: center;
      display: flex;
      justify-content: center;
      float: none !important;
      width: auto;
    }
    .git-phone-email-container img {
      float: none;
    }

    .git-details-font {
      font-family: Lokem-Headings;
      font-size: 20px;
      text-decoration: none;
      padding-bottom: 0px;
    }
    .git-email-content {
      width: auto;
      padding-left: 0;
      display: inline-flex;
    }
    .course-main1 {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      background: white;
    }

    .mainDiv1 {
      display: inline-block;
      width: 100%;
    }

    .course-color21,
    .course-color22 {
      width: 100% !important;
      background-color: white;
      font-family: Lokem-Lighter;
    }

    .course-heading {
      font-size: 27px;
      padding-bottom: 0px !important;
      font-family: Lokem-Medium;
    }
    .pressPlayText {
      font-size: 25px;
    }

    .book-place-button,
    .pathway-btn {
      border-radius: 5px !important;
    }

    .section {
      width: 100%;
      margin-bottom: 20px;
    }
    .section-title {
      font-size: 18px;
    }

    .testimonials {
      background-image: url(".././images/voice-bg.jpg");
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;
      width: 100%;
      display: inline-block;
    }

    .testimonial-image {
      border-radius: 50%;
      width: 280px;
      height: 280px;
    }
    .tm-author {
      padding-left: 35px;
    }
    .homepage-testimonial {
      width: 100%;
      font-size: 25px;
    }
    .tm-section {
      background-image: url(".././images/voice-bg.jpg");
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;
      width: 100%;
      display: inline-block;
    }
    .tm-img-div {
      width: auto;
      height: auto;
      text-align: center;
      float: initial;
      padding-top: 0px;
    }
    .quote-img-container {
      padding-left: 35px;
    }
    .tm-content-container {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;

      color: #fff;
    }

    .tm-container {
      width: auto;
      height: auto;
      display: block;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .tm-img-div {
      width: 100%;
      padding: 10px;
      text-align: center;
    }
    .tm-img {
      border-radius: 50%;
      width: 250px;
      height: 250px;
    }
    .tm-content-div {
      width: auto;
      height: auto;
      font-family: Lokem-Lighter;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 10px;
      vertical-align: initial;
      float: initial;
    }

    .git-heading {
      font-size: 30px;
    }
    .get-in-touch {
      padding-top: 50px;
      font-family: Lokem-Lighter;
      background: #f3f3f2;
      padding-bottom: 60px;
      padding-left: 3%;
      padding-right: 3%;
    }

    .git-content {
      font-size: 16px;
    }

    .nav-icons {
      bottom: 10px;
    }

    .carousel-indicators {
      bottom: 5px;
      gap: 5px;
    }

    .carousel-indicator {
      width: 18px;
      height: 18px;
    }

    .arrow-img {
      width: 50px;
      height: 50px;
    }
    .left-arrow-container {
      position: absolute;
      left: 0px; /* Offset from the left edge */
      cursor: pointer;
      text-align: center;
      margin-bottom: 22px;
    }

    .right-arrow-container {
      position: absolute;
      right: 0px; /* Offset from the right edge */
      cursor: pointer;
      margin-bottom: 22px;
    }

    .marquee-container-homepage {
      background: #fff;
      width: 98%;
      padding-top: 40px;
      padding-bottom: 40px;

      display: inline-block;
      text-align: center;
      font-size: 25px;
      font-family: "Lokem-Lighter";
    }
  }
}
