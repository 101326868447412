@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Pristina"; /* Name to be used in CSS */
  src: url("../fonts/Pristina-font.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 40px;
  padding-top: 40px;
}

.textSection {
  flex: 2;
  margin-right: 40px;
}

.title {
  font-size: 45px;
  margin-bottom: 1rem;
  font-family: "Lokem-Lighter";
  position: relative;
}

.title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 70px;
  height: 3px;
  background-color: #d3b155;
}

.introText {
  margin-bottom: 2rem;
  font-size: 16px;
  font-family: "Lokem-Medium";
  line-height: 25px;
}

.quote {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  gap: 20px;
  color: #d3b155;
}

.quoteText {
  flex: 1;
  margin: 0;
  text-align: left;
  font-family: "Lokem-Medium";
  letter-spacing: 0.2px;
  font-size: 16px;
  line-height: 25px;
}

.highlight {
  font-size: 170px;
  display: inline-block;
  line-height: 1;
  font-family: "Lokem-Medium";
}

.conclusionText {
  font-size: 16px;
  font-family: "Lokem-Medium";
  line-height: 25px;
}

.imageSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  border-radius: 50%;
  width: 320px;
  height: 300px;
  border: none;
  margin-top: 80px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .container {
    width: 100vw;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .textSection {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .title {
    font-size: 2rem;
  }
  .title::after {
    content: "";
    position: absolute;
    margin-left: 140px;
    width: 70px;
    height: 3px;
    background-color: #d3b155;
  }

  .introText,
  .quoteText,
  .conclusionText {
    font-size: 14px;
    line-height: 20px;
  }

  .quote {
    flex-direction: column;
    gap: 0px;
    text-align: center;
    margin-top: -30px;
  }

  .highlight {
    font-size: 100px;
    margin-bottom: -50px;
  }

  .imageSection {
    margin-bottom: 20px;
  }

  .image {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
}
