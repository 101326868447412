@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
body {
  margin: 0;
  font-family: Lokem-Headings !important;
  overflow-x: hidden !important;
  line-height: initial !important;
  color: #000 !important;
  background-color: #fff !important;
}
.faq-main {
  .training-overview-main {
    background-color: #f7f7f7;
  }
  .train-your-main {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    /* background-image: url(../media/images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d); */
    background-repeat: no-repeat;
    background-position: center;
  }
  .max-width-div-faq {
    width: 100%;
    display: inline-block;
  }

  .tyt-heading-main-faq {
    width: 100%;
    padding-bottom: 25px;
  }
  .tyt-heading-text-faq {
    font-size: 40px;
    font-family: Lokem-headings;
  }

  .tyt-heading-faq {
    text-align: left;
  }

  .heading-under-para {
    text-align: left;
  }

  .overview-cols-main-faq {
    width: 100%;
    display: inline-flex;
  }
  .faq-overview-col1 {
    width: 49%;
    text-align: left;
    margin-right: 1%;
  }

  .tyt-text-faq {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 25px;
    padding-left: 5px;
  }
  .question-para {
    cursor: pointer;
    color: black;
  }

  .faq-each-para-div {
    padding-bottom: 20px;
    background-color: #fff;
    margin-bottom: 15px;
    padding-top: 20px;
    padding-left: 20px;
    font-size: 14px;
  }
  .hidden-text {
    color: #ababab;
  }
  .faq-overview-col2 {
    width: 49%;
    margin-left: 1%;
    text-align: left;
  }

  @media (max-width: 995px) {
    .training-overview-main {
      background: #f7f7f7;
    }
    .train-your-main {
      display: inline-block;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: center;
      // background-image: url(../media/images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d);
      background-repeat: no-repeat;
      background-position: center;
    }
    .max-width-div-faq {
      max-width: initial;
      width: 100%;
      display: inline-block;
    }
    .tyt-heading-main-faq {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .tyt-heading-text-faq {
      font-size: 30px;
      font-family: Lokem-Medium;
    }
    .tyt-heading-faq {
      text-align: center;
    }
    .heading-under-para {
      text-align: center;
    }
    .overview-cols-main-faq {
      width: 100%;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
    }
    .faq-overview-col1 {
      width: 100%;
      text-align: center;
      margin-right: 0;
    }
    .faq-each-para-div {
      padding-bottom: 0px;
      background-color: #fff;
      margin-bottom: 15px;
      padding-top: 0px;
      padding-left: 0px;
      font-size: 14px;
      text-align: center;
    }
    .hidden-text {
      color: #ababab;
    }
    .faq-overview-col2 {
      width: 100%;
      margin-left: 0;
      text-align: center;
    }
  }
}
