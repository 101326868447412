@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.add-training-dates-container {
  background-color: #f9f9f9;

  .add-training-wrapper {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 20px;
    font-family: "Lokem-Medium", sans-serif;
  }

  h1 {
    font-family: "Lokem-Medium", sans-serif;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    text-align: left;
  }

  .form {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .form-group {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      label {
        width: 20%;
        margin: auto;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-family: "Lokem-Medium", sans-serif;
      }

      input {
        width: 95%;
        height: 20px;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 0.8rem;
        font-family: "Lokem-Medium", sans-serif;
        margin-left: 0.3rem;

        &:focus {
          outline: none;
          border-color: #005fcc;
        }
      }

      select {
        width: 97%;
        height: 38px;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 0.8rem;
        font-family: "Lokem-Medium", sans-serif;

        &:focus {
          outline: none;
          border-color: #005fcc;
        }
      }
    }

    .submit-btn {
      width: 100px;
      margin: auto;
      background-color: #c3a555;
      color: white;
      font-size: 1rem;
      font-family: "Lokem-Medium", sans-serif;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #b18b4e;
      }
    }
  }
}

@media (max-width: 768px) {
  .add-training-dates-container {
    height: 500px;
    margin-bottom: 1rem;
    .add-training-wrapper {
      width: 100%;
      padding: 0px;
    }

    h1 {
      font-family: "Lokem-Medium", sans-serif;
      font-size: 1.3rem !important;
      margin-bottom: 1.5rem;
    }

    .form {
      width: 100%;

      .form-group {
        display: flex;
        flex-direction: column;
        text-align: left;

        label {
          width: 50%;
          margin: auto;
          margin-left: 1rem;
          margin-bottom: 0.3rem;
        }

        input {
          width: 90%;
          margin-left: 0.3rem;
        }

        select {
          width: 95%;
          margin-left: 0.3rem;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.3rem;
    text-align: center;
  }

  .form {
    width: 100%;
  }

  .form-group {
    label {
      font-size: 0.9rem;
    }

    input,
    select {
      font-size: 0.8rem;
    }
  }

  .submit-btn {
    font-size: 0.9rem;
    padding: 10px;
  }
}
