// Media Query for max-width: 768px
@media (max-width: 768px) {
  .vision-section,
  .mission-section {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align items */
    text-align: center; /* Center-align text */

    .vision-content,
    .mission-content {
      padding: 0 10px;

      h2 {
        font-size: 2rem; /* Adjust font size */
      }

      p {
        font-size: 1rem; /* Adjust paragraph font size */
      }
    }

    .vision-image,
    .mission-image {
      margin-top: 20px; /* Add some spacing above the image */
      margin-bottom: 20px; /* Add some spacing above the image */

      img {
        width: 200px; /* Adjust image size */
        height: 200px; /* Maintain aspect ratio */
      }
    }
  }
}
