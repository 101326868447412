@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.effective-main {
  .train-your-main-effect {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
  }
  .image-blog-effective-effect {
    width: 40%;
    margin: auto;
  }
  .blogs-image-effective-effect {
    width: 100%;
    height: 290px;
  }
  .effective-font-effect {
    font-family: Lokem-Medium;
    font-size: 20px;
    margin-left: 230px;
    margin-right: 230px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blog-welcome-text-effect {
    width: 100%;
    text-align: left;
    font-family: Lokem-Lighter;
    font-size: 20px;
    padding-top: 25px;
  }
  .effective-font-header-effect {
    font-family: Lokem-Medium;
    font-size: 20px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 60px;
    margin-bottom: 50px;
  }
  .tyt-paras-effect {
    width: 100%;
    display: inline-flex;
  }
  .secs-tyt-cols-effect {
    width: 100%;
    display: flex;
  }
  .tyt-text-effect {
    font-family: "Lokem-Lighter";
    font-size: 20px;
    width: 60%;
  }
  .each-para-div-effect {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
  }
  .image-blog2-effect {
    width: 36%;
    margin-top: 0px;
    object-fit: cover;
    margin-left: 30px;
    margin-right: 30px;
  }
  .para-heigh-effect {
    height: 280px;
  }
  .other-blogs-new-effect {
    width: 65%;
  }
  .bold-font-effect {
    font-size: 20px;
    font-family: lokem-medium;
  }
  .para-height-effect {
    height: 280px;
  }
  .image-blog3-effect {
    width: 36%;
    margin-top: 0px;
    object-fit: cover;
    margin-right: 30px;
  }
  .other-blogs-new1-effect {
    width: 100%;
  }
  .each-para-div1-effect {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
    align-items: center;
    display: flex;
  }
  .tyt-text1-effect {
    font-family: "Lokem-Lighter";
    font-size: 20px;
    width: 60%;
    display: flex;
  }
  .share-your-network {
    margin-top: 25px;
  }

  @media (max-width: 995px) {
    .train-your-main-effect {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .image-blog-effective-effect {
      width: 100%;
      margin-top: 0;
      /* object-fit: cover;*/
      margin-left: 0px;
      margin-right: 0px;
      margin: auto;
    }
    .blogs-image-effective-effect {
      width: 100%;
      padding-top: 20px;
    }
    .effective-font-effect {
      font-family: Lokem-Medium;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 20px;
      margin-bottom: 0px;
      width: 100%;
      text-align: center;
    }
    .blog-welcome-text-effect {
      width: 100%;
      text-align: center;
      font-size: 18px;
      padding-top: 0;
    }
    .effective-font-header-effect {
      font-family: Lokem-Medium;
      font-size: 20px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 20px;
      margin-bottom: 0px;
      width: 100%;
      text-align: center;
    }
    .tyt-paras-effect {
      width: 100%;
      display: block;
    }
    .secs-tyt-cols-effect {
      width: 100%;
      display: block;
    }
    .tyt-text-effect {
      font-family: "Lokem-Lighter";
      font-size: 20px;
      width: 100%;
    }
    .each-para-div-effect {
      font-size: 17px;
      text-align: center;
      width: 100%;
    }
    .image-blog2-effect {
      width: 100%;
      margin-top: 10px;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
    }
    other-blogs-new-effect {
      width: 100%;
    }
    .blog-welcome-text-effect {
      width: 100%;
      text-align: center;
      font-size: 18px;
      padding-top: 0;
    }
    .image-blog3-effect {
      width: 100%;
    }
    .tyt-text1-effect {
      width: 100%;
    }
    .each-para-div1-effect {
      font-size: 18px;
    }
    .train-your-main {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .row-blogs-section {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }
    .column-blogs-section {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }
  }
}
