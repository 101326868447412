@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.add-signup-link-container {
  font-family: "Lokem-Medium", sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  padding: 2rem;

  h1 {
    font-size: 1.8rem;
    text-align: left;
    margin-bottom: 2rem;
    font-weight: 600;
    color: #000;
  }

  .signup-link-wrapper {
    display: flex;
    justify-content: center;

    .signup-link-form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 60%;

      .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-family: "Lokem-Medium", sans-serif;
          font-size: 1rem;
          font-weight: 600;
          margin-right: 1rem;
          flex: 0 0 150px;
          text-align: right;
          color: #000;
        }

        input {
          flex: 1;
          padding: 0.6rem 1rem;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;

          &:focus {
            border-color: #005fcc;
          }
        }
      }

      .submit-btn {
        align-self: center;
        background-color: #cba53c;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 0.8rem 2rem;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #b18b4e;
        }
      }
    }
  }

  // Media Queries for Responsiveness
  @media (max-width: 768px) {
    .signup-link-wrapper {
      .signup-link-form {
        width: 90%;

        .form-group {
          flex-direction: column;
          align-items: flex-start;

          input {
            font-size: 0.9rem;
            padding: 0.7rem;
          }
        }

        .submit-btn {
          width: 100%;
          font-size: 0.9rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .signup-link-wrapper {
      .signup-link-form {
        width: 100%;
        padding: 0 1rem;

        .form-group {
          label {
            font-size: 0.9rem;
            margin-bottom: 0.3rem;
          }

          input {
            width: 300px;
            font-size: 0.9rem;
            padding: 0.6rem;
            margin-top: -7rem;
          }
        }

        .submit-btn {
          font-size: 0.85rem;
          padding: 0.7rem;
          width: 100%;
        }
      }
    }
  }
}
