@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.reqCBMain {
  padding-top: 60px;
}
.reqCBHeading {
  text-align: center;
  font-size: 32px;
  color: white;
  margin-bottom: 10px;
  font-family: Lokem-Medium;
}
.callbackFormMain {
  margin-left: 7%;
  margin-right: 7%;
}
.callbackFormMain .contactMain {
  width: 100%;
  display: block;
}
.contactMainForm {
  display: inline-flex;
  width: 100%;
}
.contactFieldDiv {
  width: 19%;
  margin-right: 1%;
}
.contactInput {
  width: 100%;
  height: 36px;
  padding: 0;
  border: none;
  padding-left: 6px;
  border-radius: 3px;
  vertical-align: middle;
  font-size: 18px;
}
.callbackFormMain select {
  font-size: 13px !important;
}
.contactSubmitBtn {
  width: 100%;
  background: #cba53c;
  border: 1px solid #cba53c;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  font-family: Lokem-Lighter;
  height: 37px;
  cursor: pointer;
  vertical-align: middle;
}
.btnSpinner {
  display: none;
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.contactMsgMain {
  padding-top: 10px;
  color: #f0b512;
  font-size: 20px;
  text-align: left;
}
.error-text {
  color: #df1b41;
  font-weight: bold;
  font-size: 15px;
  font-family: Lokem-Headings;
  text-align: left;
  margin-top: 5px;
  margin-left: 2px;
}
@media (max-width: 995px) {
  .reqCBMain {
    padding-top: 30px;
  }
  .callbackFormMain .contactMain {
    width: 94%;
    display: inline-block;
    margin-left: 3%;
    margin-right: 3%;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
  }
  .contactMainForm {
    display: inline-block;
    width: 94%;
    margin: auto;
  }
  .contactFieldDiv {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    text-align: center;
  }

  .contactInput {
    width: 100%;
    height: 36px;
    padding: 0;
    border: none;
    border-radius: 3px;
    vertical-align: middle;
    font-size: 18px;
    text-align: center;
  }
  .contactSubmitBtn {
    width: 100%;
    background: #cba53c;
    border: 1px solid #cba53c;
    color: #fff;
    font-size: 24px;
    border: none;
    border-radius: 3px;
    font-family: Lokem-Lighter;
    cursor: pointer;
    vertical-align: middle;
  }
}
