@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.main-comments {
  .train-your-main-comments {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
  }

  .row-comments {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 25px;
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .coloumn-comments {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }
  .other-blogs-img-comments {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .prev-next-post-comments {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .post-name-comments {
    font-size: 17px;
    margin-top: 5px;
  }

  .rate-article-text-comments {
    font-size: 17px;
  }

  .rating-comments {
    font-size: 35px;
  }

  .rating-details-text-comments {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-comments {
    margin-top: 25px;
  }

  .social-text-comments {
    font-size: 20px;
  }

  .blog-social-media-icons-comments {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .side-header-comments {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }
  .comment-text-head-comments {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }
  .comment-icon-comments {
    font-size: 40px;
    margin-left: 15px;
  }
  .hrLine {
    width: 100%;
  }
  @media (max-width: 995px) {
    .train-your-main-comments {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .row-comments {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }
  }
}
