@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.scrumBenefit-main {
  .train-your-main-scrum {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
  }

  .blog-welcome-text-scrum {
    width: 100%;
    text-align: left;
    font-family: Lokem-Medium;
    font-size: 20px;
  }

  .tyt-paras-scrum {
    width: 100%;
    display: inline-flex;
  }

  .secs-tyt-cols-scrum {
    width: 100%;
    display: flex;
  }

  .tyt-text-scrum {
    font-family: "Lokem-Lighter";
    font-size: 20px;
    width: 60%;
  }

  .tyt-sub-heading-scrum {
    text-align: left;
    font-size: 30px;
    font-family: "Lokem-Medium";
    margin-bottom: 10px;
  }

  .each-para-div-scrum {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
  }

  .image-blog-scrum {
    width: 36%;
    margin-top: 55px;
    object-fit: cover;
    margin-left: 30px;
    margin-right: 30px;
  }

  .other-blogs-new-scrum {
    width: 100%;
  }

  .padding-top-para-scrum {
    padding-top: 20px;
  }

  .each-para-blog-scrum {
    width: 100%;
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;
    font-family: Lokem-Lighter;
  }

  .image-blog2-scrum {
    width: 36%;
    margin-top: 35px;
    object-fit: cover;
    margin-left: 30px;
    margin-right: 30px;
  }

  .tyt-text-blog-scrum {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .row-scrum {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 25px;
  }

  .column-scrum {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-scrum {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .prev-next-post-scrum {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .post-name-scrum {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-scrum {
    padding-top: 10px;
  }

  .rate-article-text-scrum {
    font-size: 17px;
  }

  .rating-scrum {
    font-size: 35px;
  }

  .rating-details-text-scrum {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-scrum {
    margin-top: 25px;
  }

  .social-text-scrum {
    font-size: 20px;
  }

  .blog-social-media-icons-scrum {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .text-box-scrum {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .side-header-scrum {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }
  .comment-text-head-scrum {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }
  .comment-icon-scrum {
    font-size: 40px;
    margin-left: 15px;
  }
  .hrLine {
    width: 100%;
  }
  .comment-container-scrum {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .circle-scrum {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #d3b155;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 30px;
    font-family: Lokem-Lighter;
  }
  .rating-comment-scrum {
    margin-left: 25px;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  .comment-text-scrum {
    font-size: 20px;
    font-family: "Lokem-Lighter" !important;
  }
  @media (max-width: 995px) {
    .train-your-main-scrum {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 10px;
      padding-top: 20px;
    }

    .blog-welcome-text-scrum {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }

    .secs-tyt-cols-scrum {
      width: 100%;
      display: block;
    }

    .tyt-text-scrum {
      font-family: "Lokem-Lighter";
      font-size: 20px;
      width: 100%;
    }

    .tyt-sub-heading-scrum {
      text-align: center;
      font-size: 25px;
      font-family: "Lokem-Medium";
      margin-bottom: 10px;
    }

    .each-para-div-scrum {
      font-size: 17px;
      text-align: center;
      width: 100%;
    }

    .image-blog-scrum {
      width: 100%;
      margin-top: 10px;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
    }

    .other-blogs-new-scrum {
      width: 100%;
    }

    .each-para-blog-scrum {
      width: 100%;
      font-size: 17px;
      text-align: center;
      font-family: "Lokem-Lighter";
      margin-bottom: 15px;
    }

    .image-blog2-scrum {
      width: 100%;
      margin-top: 10px;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
    }

    .other-blogs-new {
      width: 100%;
    }

    .each-para-blog-scrum {
      width: 100%;
      font-size: 17px;
      text-align: center;
      font-family: "Lokem-Lighter";
      margin-bottom: 15px;
    }

    .row-scrum {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column-scrum {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }
  }
}
