@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.privacyPolicy-main{
.privacy-banner {
  background: url("../images/voice-bg.jpg") no-repeat center center;
  height: 320px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1.banner-title {
  font-size: 60px;
  color: white;
  font-family: "Lokem-Lighter";
  margin-top: 10px;
}

.privacy-body {
  width: 90%;
  justify-content: center;
  text-align: left;
  margin: 0 auto;
}

.title-text {
  font-size: 27px;
  color: black;
  margin-top: 30px;
  font-family: "Lokem-Headings";
}

h3.subtext {
  font-size: 27px !important;
  color: black;
  margin: 20px 0;
  font-family: "Lokem-Headings";
}

.privacy-text {
  font-size: 20px !important;
  color: black;
  margin-top: 10px;
  font-family: "Lokem-Lighter" !important;
}

ul {
  margin-left: 20px;
  list-style-type: disc;

  li {
    color: black;
    font-size: 20px;
    font-family: "Lokem-Lighter";
  }
}

strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .privacy-banner {
    height: 220px;
    text-align: center;
    justify-content: center;
    background-size: auto;

    h1.banner-title {
      padding-left: 20px;
      text-align: left;
      font-size: 45px !important;
    }
  }

  h2 {
    text-align: left;
  }

  h3.subtext {
    text-align: left;
  }

  .privacy-text {
    text-align: left !important;
  }
}
}