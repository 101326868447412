@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Charity-Font";
  src: url("../fonts/Pristina-font.ttf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: Lokem-Headings !important;
  overflow-x: hidden !important;
  line-height: initial !important;
  color: #000 !important;
  background-color: #fff !important;
}

.aboutus-main {
  .vision-mission-main {
    background: #fff !important;
    padding-bottom: 80px !important;
    padding-top: 80px !important;
  }

  .train-your-main-about {
    width: 86%;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    background-image: url(../images/our3cBG.png),
      linear-gradient(to right, #ebd9a5, #e4cd8d);
    background-repeat: no-repeat;
    background-position: center;
  }

  .max-width-div-about {
    max-width: 1200px;
    width: 100%;
    display: inline-block;
  }

  .overview-cols-main {
    width: 100%;
    display: inline-flex;
  }

  .right-col1 {
    text-align: right !important;
    width: 100%;
  }

  .overview-col1 {
    width: 75%;
    text-align: left;
  }

  .our-vision-main {
    width: 90%;
    float: right;
  }

  .right-heading-main {
    float: right !important;
    width: 100%;
  }

  .vision-right {
    text-align: right !important;
  }

  .tyt-heading-text-about {
    font-size: 45px;
    font-family: Lokem-Lighter;
  }

  .tyt-heading-about {
    text-align: left;
  }

  .tyt-heading-line-main {
    margin-bottom: 30px;
  }

  .right-line {
    float: right !important;
  }

  .tyt-heading-line {
    width: 60px;
    border: 1.9px solid #d3b155;
    background: #d3b155;
    float: left;
    margin-left: 3px;
  }

  .tyt-text-about {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 25px;
  }

  .overview-col2 {
    float: right;
    padding-left: 30px;
  }

  .course-overview-image1 {
    float: left;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
  }

  .overview-cols-main {
    width: 100%;
    display: inline-flex;
  }

  .row2-col2-padding {
    padding-right: 30px;
  }

  .left-col1 {
    text-align: left;
  }

  .left-line {
    float: left !important;
  }

  .only-mobile {
    display: none;
  }

  //   /* Our 3Cs section *///

  .tyt-col1-about {
    text-align: left;
  }

  .tyt-heading-line-main-about {
    margin-bottom: 30px;
  }

  .tyt-heading-line-about {
    width: 60px;
    border: 1.9px solid #d3b155;
    background: #d3b155;
    float: left;
    margin-left: 3px;
  }

  .our-3cs-image-main-about {
    padding-left: 45px;
    object-fit: cover;
  }

  .our-3cs-image-about {
    border-radius: 50%;
    width: 400px;
    height: 400px;
    border: 1px solid black;
    border: none;
  }

  .tyt-col2-about {
    width: 50%;
    margin-left: 80px;
    text-align: left;
    padding-top: 20px;
  }

  .each-para-div-about {
    padding-bottom: 20px !important;
  }

  .tyt-sub-heading-about {
    font-size: 25px;
    color: #bd8f14;
    font-family: "Lokem-Medium";
    padding-bottom: 10px;
  }

  //section-4//

  .founders-main-about {
    background: #fff !important;
  }

  .tyt-paras-about {
    width: 100%;
    display: inline-flex;
  }

  .founder-tyt-col1-about {
    width: 65%;
    margin-right: 0;
    text-align: left;
  }

  .offer-para1-about {
    margin-top: 0px;
  }

  .quote-text-main-about {
    width: 100%;
    color: #d3b155;
    display: inline-flex;
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .quote-about {
    font-size: 170px;
    margin-top: 60px;
    margin-right: 20px;
  }

  .quote-text-about {
    width: 85%;
    margin-left: 10px;
  }

  .founder-tyt-col2-about {
    margin-left: 20px;
    text-align: left;
    width: min-content;
  }

  .our-founders-image-main-about {
    float: left;
    margin-left: 60px;
  }

  .our-founders-image-about {
    border-radius: 50%;
    width: 320px;
    height: 320px;
    border: 1px solid black;
    border: none;
    margin-bottom: 70px;
    object-fit: cover;
  }

  //section-5//

  .lokem-journey-col1-about {
    width: 80%;
    margin-right: 0;
    text-align: left;
  }

  .each-para-div-journey-about {
    padding-bottom: 10px;
  }

  .our-trainees-heading-about {
    color: #bd8f14;
    font-family: "Lokem-Medium";
    padding-bottom: 10px;
    margin-top: 20px;
    font-size: 30px;
  }

  .lokem-journey-image-main-about {
    text-align: center;
  }

  .lokem-journey-image-about {
    width: 700px;
    height: 400px;
  }

  .lokem-journey-col2-about {
    width: 20%;
    position: relative;
    float: left;
    text-align: left;
  }

  .position-abs-about {
    position: absolute;
    bottom: 0;
  }

  .over-trainees-about {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .over-trainees-about li {
    list-style: square;
    font-family: "Lokem-Medium";
    font-size: 16px;
  }

  //section-6//
  .charity-page {
    background-image: url("../images/charity-img.jpeg");
    min-height: 650px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  .charity-page-container {
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 0;
    color: #fff;
    width: 90%;
  }

  .charity-banner-heading {
    font-family: "Lokem-Lighter";
    font-size: 28px;
    color: #fff;
    line-height: 44px;
  }

  .charity-page-heading {
    padding-bottom: 0px;
  }

  .p1 {
    font-size: 26px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-family: "Lokem-Lighter";
  }

  .p2 {
    font-size: 26px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    font-family: "Lokem-Lighter";
    margin-top: 50px;
  }

  .charity-font {
    font-size: 40px;
    font-family: "Charity-Font";
    font-family: "Pristina";
    font-weight: 300;
  }

  // Our 3Cs //
  .section-3cs__content {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .section-3cs__image,
  .section-3cs__details {
    width: 100%;
    text-align: center;
  }

  .section-3cs__image img {
    max-width: 250px;
    margin-bottom: 20px;
  }

  .section-3cs__header {
    text-align: center;
  }

  // Media Query for max-width: 768px
  @media (max-width: 995px) {
    .vision-right {
      text-align: center !important;
    }
    .train-your-main-about {
      display: inline-block;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: center;
    }
    .vision-mission-main {
      background: #fff !important;
      padding-bottom: 40px !important;
      padding-top: 40px !important;
    }
    .max-width-div-about {
      max-width: 1200px;
      width: 100%;
      display: inline-block;
    }
    .overview-cols-main {
      width: 100%;
      display: inline-block;
    }
    .overview-col1 {
      width: 100%;
      text-align: center !important;
    }
    .tyt-heading-about {
      text-align: center;
    }
    .tyt-heading-text-about {
      font-size: 30px;
      font-family: Lokem-Lighter;
    }
    .our-vision-main {
      width: 100%;
      float: initial;
    }
    .right-col1 {
      text-align: center !important;
    }
    .right-heading-main {
      float: initial !important;
      width: 100%;
    }
    .right-line {
      float: initial !important;
    }
    .tyt-heading-line-about {
      width: 60px;
      border: 1.9px solid #d3b155;
      background: #d3b155;
      float: initial;
      margin-left: auto;
    }
    .overview-col2 {
      float: initial;
      padding-left: 0;
    }
    .course-overview-image1 {
      float: initial;
      width: 300px;
      height: 300px;
      border-radius: 50%;
    }
    .overview-cols-main {
      width: 100%;
      display: inline-block;
    }
    .left-line {
      float: initial !important;
    }
    .only-mobile {
      display: block;
    }
    .only-desktop {
      display: none;
    }
    .left-col1 {
      padding-top: 30px;
    }
    .our-mission-padding {
      padding-top: 10px;
    }
    .tyt-paras-about {
      width: 100%;
      display: inline-block;
    }
    .tyt-col1-about {
      width: 100%;
      text-align: center;
    }
    .our-3cs-image-main-about {
      padding-top: 0;
      padding-left: 0;
    }
    .our-3cs-image-about {
      border-radius: 50%;
      width: 300px;
      height: 300px;
      border: none;
    }
    .tyt-col2-about {
      width: 100%;
      margin-left: 0;
      text-align: center;
      padding-top: 0;
    }
    .founder-tyt-col1-about {
      width: 100%;
      text-align: center;
    }
    .quote-text-main-about {
      width: 100%;
      color: #d3b155;
      display: inline-flex;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .quote-text-about {
      width: 70%;
      margin-left: 10px;
    }
    .founder-tyt-col2-about {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
    .our-founders-image-main-about {
      float: initial;
      margin-left: 0;
      margin-top: 25px;
    }
    .our-founders-image-about {
      border-radius: 50%;
      width: 300px;
      height: 300px;
      border: 1px solid black;
      border: none;
      margin-bottom: 0;
    }
    .lokem-journey-col1-about {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
    .lokem-journey-col2-about {
      width: 100%;
      margin-left: 0;
      text-align: center;
    }
    .position-abs-about {
      position: relative;
      bottom: 0;
    }
    .lokem-journey-image-about {
      width: 350px;
      height: 236px;
    }
    .over-trainees-about li {
      list-style: none;
      padding-bottom: 10px;
    }
    .charity-page {
      background-image: url("../images/charity-img.jpeg");
      min-height: 390px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
    }
    .charity-page-container {
      text-align: center;
      padding-top: 0;
      color: #fff;
      width: 100%;
    }
    .charity-banner-heading {
      font-family: "Lokem-Lighter";
      font-size: 18px;
      color: #fff;
      line-height: 30px;
      padding: auto;
    }
    .p1 {
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      color: #fff;
      font-family: Lokem-Lighter;
    }
    .charity-font {
      font-size: 20px;
      font-family: "Charity-Font";
    }
    .p2 {
      font-size: 20px;
      padding-left: 20px;
      padding-right: 20px;
      color: #fff;
      font-family: Lokem-Lighter;
      margin-top: 50px;
    }
  }
}
