@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.header-top-main {
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .header-container {
    display: block;
    width: 100%;
  }
  .logo-container {
    width: 100%;
    height: auto;
    text-align: left;
    padding-left: 20px;
    display: inline-block;
  }
  .logo-img {
    width: 250px;
    cursor: pointer;
    margin-top: 10px;
  }
  .menu-container {
    position: absolute;
    display: flex;
    right: 65px;
    top: 10px;
  }
  .bars-icon {
    color: #000;
    padding: 15px;
    display: block;
    font-size: 20px;
    float: right;
    padding-right: 6%;
    margin-top: 10px;
  }
  .fa-bars {
    font-size: 40px !important;
    color: #cba53c;
    cursor: pointer;
  }
  .menu-items-container {
    padding-right: 0px;
    color: #fff;
    position: absolute;
    right: 0px;
    top: 75px;
    font-family: Lokem-Headings;
    z-index: 1000;
  }
  .menu-items {
    color: #000;
    padding-right: 60px;
    display: none;
  }
  .menu-items.open {
    display: flex; /* Show the menu when open */
    text-decoration: none;
  }
  .first-page-container1 {
    text-align: left;
    font-size: 50px;
    margin-left: 15px;
    margin-right: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #000;
  }
  .font-size-index {
    font-size: 25px !important;
  }
  .first-page-heading1 {
    font-family: Lokem-Lighter;
    font-size: 45px;
  }
  ul li a {
    text-decoration: none;
    color: #000;
  }
  .menu-items li {
    float: none;
    text-align: right;
    padding: 5px;
    font-size: 21px;
    font-family: Lokem-Lighter;
    padding-right: 10px;
    display: inline-flex;
    /* background: #c5c5c5; */
    border-radius: 5px;
    padding-top: 2px;
    text-decoration: none;
  }
  .menu-items li a:hover {
    color: #c3a555; /* Text color on hover inside the submenu */
  }
  .sub-dropdown {
    margin-top: 2px;
    margin-left: 5px;
    font-size: 18px;
  }
  .dropup-content {
    display: none;
    position: absolute;
    background-color: #cdb984;
    min-width: 160px;
    top: 35px;
    z-index: 1;
    text-align: left;
  }
  .lokem-button {
    border: none !important;
    border-radius: 5px !important;
  }

  .login-button {
    height: 35px;
    width: 115px;
    background: #c9a328;
    border: 2px solid #c9a328;
    border-radius: 7px;
    color: black;
    font-size: 15px;
    cursor: pointer;
    font-family: "Lokem-Medium";
  }

  .dropup-content.show {
    display: block;
  }

  .dropup:hover .dropup-content {
    display: block; // Show dropdown content on hover
  }

  .has-submenu {
    position: relative;
    font-size: 16px;
  }
  .dropup-content a {
    font-size: 16px; /* Reduced font size for submenu items */
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    color: white;
  }
  .dropup-content a:hover {
    background-color: #806323;
  }

  .bars-icon i {
    font-size: 20px; /* Adjust size of the icon */
    font-weight: 1000; /* Increase line thickness */
    line-height: 1; /* Ensure proper spacing */
    letter-spacing: 0; /* Remove extra spacing between lines */
  }

  @media (max-width: 995px) {
    .logo-img {
      width: 150px;
      cursor: pointer;
      margin-left: 0px;
      margin-top: 0px;
    }
    .header-top-main {
      background: #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .logo-container {
      width: auto;
      margin-left: 0;
      height: auto;
      padding-top: 20px;
      text-align: center;
      display: inline-block;
    }
    .menu-container {
      position: absolute;
      display: flex;
      right: 20px;
      top: 20px;
    }
    .bars-icon {
      color: white;
      padding: 0px;
      padding-left: 15px;
      display: block;
      font-size: 20px;
      float: right;
      padding-right: 10%;
      margin-top: 10px;
    }
    .menu-items-container {
      padding-right: 0px;
      color: #fff;
      position: absolute;
      right: 0px;
      top: 75px;
      font-family: Lokem-Headings;
      z-index: 1000;
      background: #a38732;
      width: 100%;
    }
    .menu-items {
      padding-right: 0px;
      margin: 0;
      color: #fff;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    .menu-items li {
      float: none;
      display: block;
      text-align: right;
      padding-bottom: 20px;
      font-size: 21px;
      font-family: Lokem-Lighter;
      padding-right: 68px;
    }
    .first-page-container1 {
      text-align: left;
      font-size: 20px;
      margin-left: 3%;
      margin-right: 3%;
      padding-top: 10px;
    }
    .font-size-index {
      font-size: 18px !important;
    }
    .menu-items.open {
      display: block; /* Show the menu when open */
      text-decoration: none;
    }
    ul li a {
      text-decoration: none;
      color: #fff;
    }
    .login-button {
      height: 35px;
      width: 90px;
      background: #c9a328;
      border: 2px solid #c9a328;
      border-radius: 7px;
      color: black;
      font-size: 14px;
      cursor: pointer;
      font-family: "Lokem-Medium";
    }
  }
}
