@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.coronavirus-main {
  .train-your-main-corona {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    max-width: 1200px;
    margin: auto;
  }

  .tyt-paras-corona {
    width: 100%;
    display: inline-flex;
  }

  .secs-tyt-cols-corona {
    width: 100%;
    text-align: left;
  }

  .tyt-text-corona {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
  }

  .each-para-div-corona {
    padding-bottom: 25px;
  }

  .para-text-corona {
    font-family: "Lokem-Medium" !important;
  }

  .tyt-sub-heading-corona {
    font-size: 28px;
    color: #000;
    font-family: "Lokem-Medium";
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .flex-dir-corona {
    flex-direction: row-reverse;
  }

  .tyt-paras-corona {
    width: 100%;
    display: inline-flex;
  }

  .tyt-text-blog-corona {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .row-corona {
    display: flex;
    text-align: center;
    justify-content: space-between;
  }

  .column-corona {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-corona {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .post-name-corona {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-corona {
    padding-top: 10px;
  }

  .rate-article-text-corona {
    font-size: 17px;
  }

  .rating-corona {
    font-size: 35px;
  }

  .rating-details-text-corona {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-corona {
    margin-top: 25px;
  }

  .social-text-corona {
    font-size: 20px;
  }

  .social-icons-main-corona {
    margin-top: 14px;
  }

  .blog-social-media-icons-corona {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .blog-social-media-icons-corona i {
    width: 20px;
  }

  .fa-corona {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .prev-next-post-corona {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .side-header-corona {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }

  .comment-text-head-corona {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }

  .comment-icon-corona {
    font-size: 40px;
    margin-left: 15px;
  }

  .courses-main-corona {
    background-color: #fff !important;
    margin-top: 40px;
  }

  .hrLine-corona {
    width: 100%;
  }

  @media (max-width: 995px) {
    .tyt-paras-corona {
      width: 100%;
      display: inline-block;
    }

    .tyt-text-corona {
      text-align: left;
      font-size: 17px;
    }

    .tyt-sub-heading-corona {
      font-size: 25px;
      color: #000;
      font-family: "Lokem-Medium";
    }

    .tyt-sub-heading-corona {
      font-size: 25px;
      color: #000;
      font-family: "Lokem-Medium";
    }

    .row-corona {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column-corona {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }

    .row-last-corona {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0px;
      border: 1px solid grey;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-color: #dee1e5;
      padding-left: 0px;
    }
  }
}
