@font-face {
  font-family: "Pristina"; /* Name to be used in CSS */
  src: url("../fonts/Pristina-font.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.training-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
  text-align: center;
  padding: 1rem;

  h2 {
    font-family: "Lokem-Headings", sans-serif;
    opacity: 0.6;
  }
}

.training-form-container {
  width: 55%;
  margin: auto;
  border: 1.5px solid #737373;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;

  .right-side {
    width: 65%;
    font-family: "Lokem-Headings", sans-serif;
    text-align: left;
    opacity: 1;
    padding-left: 1rem;
    border-right: 1px solid #dfe0e1;

    .line {
      width: 95%;
      border-top: 0.5px solid #625c5c;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 23px;
    }

    .form {
      .inputfield {
        width: 420px;
        padding: 10px;
        position: relative;

        input,
        select {
          width: 100%;
          padding: 0.5rem;
          border: none;
          height: 35px;
          border: 1px solid #e6e6e6;
          box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
          font-family: "Lokem-Medium", sans-serif;
          border-radius: 5px;
          box-sizing: border-box;
        }

        ::placeholder {
          color: #737373;
          opacity: 0.6;
          font-size: 0.8rem;
        }

        .error-message {
          color: red;
          font-family: "Lokem-Headings", sans-serif;
          font-size: 0.8rem;
          position: absolute;
          margin-top: 0.2rem;
          top: 45px;
          left: 15;
        }
      }
    }

    .payment-schedule {
      h2 {
        font-family: "Lokem-Headings", sans-serif;
        opacity: 0.6;
      }

      .non-refundable {
        font-size: 13px;
        padding-left: 5px;
        font-family: "Lokem-Headings", sans-serif;
        color: #c3a555;
        margin-top: -1rem;
        opacity: 0.6;
      }

      .options {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-family: "Lokem-Lighter", sans-serif;
        font-size: 0.9rem;
      }

      .option {
        position: relative;
      }

      .option input[type="radio"]:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: 1px;
        position: absolute;
        background-color: #c3a555;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }
    }

    .coupon-section {
      padding-bottom: 2rem;

      .error-message {
        color: red;
        font-family: "Lokem-Headings", sans-serif;
        font-size: 0.8rem;
        position: absolute;
        margin-top: 0.2rem;
        left: 15;
      }

      .coupon-input {
        display: flex;
        gap: 2rem;

        input {
          width: 200px;
          padding: 0.5rem;
          border: none;
          height: 35px;
          border: 1px solid #e6e6e6;
          box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
          font-family: "Lokem-Medium", sans-serif;
          border-radius: 5px;
          box-sizing: border-box;
        }

        a {
          color: #c3a555;
          font-family: "Lokem-Lighter", sans-serif;
          font-size: 0.9rem;
          margin-left: 10px;
          line-height: 2rem;
          font-weight: 400;
        }
      }
    }
  }

  .left-side {
    width: 35%;
    font-family: "Lokem-Headings", sans-serif;
    text-align: left;
    padding-right: 1rem;
    margin-top: -10rem;

    h4 {
      opacity: 0.6;
    }

    ul li {
      font-family: "Lokem-Lighter", sans-serif;
      text-align: left;
      font-size: 12px;
      letter-spacing: 1px;
      opacity: 1;
      line-height: 25px;
      color: #000;
    }

    .amount-section {
      h3,
      h5 {
        opacity: 0.6;
      }
      .use-code {
        color: #000 !important;
        opacity: 2;
        font-family: "Lokem-Headings", sans-serif;
        font-size: 13px;
        margin-top: -10px;
      }

      .coupon-code {
        animation: blinker 1s linear infinite;
        color: #c3a555;

        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      }
    }

    .payment {
      margin-top: 5px;
      text-align: left;
      margin-bottom: 6px;
      padding-bottom: 2%;
      width: 100%;

      .payment-btn {
        background-color: #c3a555;
        cursor: pointer;
        padding: 10px 7px 10px 7px;
        border-radius: 4px;
        color: #ffffff;
        border: none;
        text-align: left;
        border-radius: 5px;
        font-size: 13px;
        font-family: Lokem-Lighter;
      }
    }
  }
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .training-form {
    margin-top: 0px;

    h2 {
      font-size: 2rem;
    }
  }

  .training-form-container {
    width: 95%;
    flex-direction: column;
    border: none;
    border-radius: none;

    .right-side {
      width: 100%;
      padding-left: 0rem;
      padding-right: 1rem;
      border-right: none;
      margin-top: -1rem;

      h2 {
        font-size: 1.7rem;
      }

      .line {
        display: none;
      }

      .form {
        .inputfield {
          width: 350px;
        }
      }

      .payment-schedule {
        padding: 0.5rem;

        .non-refundable {
          font-size: 10px;
          margin-top: -1rem;
          margin-bottom: 1rem;
          margin-left: -3rem;
        }

        .options {
          gap: 1.2rem;
          font-size: 1.2rem;
        }

        .option input[type="radio"]:checked:after {
          top: 1px;
        }
      }

      .coupon-section {
        .coupon-input {
          a {
            font-size: 1rem;
            margin-left: 0px;
          }
        }
      }
    }

    .left-side {
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      margin-top: 0.5rem;
      padding: 0.5rem;

      ul li {
        font-size: 16px;
        line-height: 30px;
      }

      .lsh4 {
        margin-top: -0.5rem;
        font-size: 1.6rem;
        margin-bottom: -0.5rem;
      }

      .amount-section {
        padding: 0.5rem;

        h3 {
          font-size: 1.7rem;
        }
        h5 {
          margin-top: -0.5rem;
          font-size: 1.2rem;
        }
        .use-code {
          font-size: 18px;
          margin-top: -10px;
        }
      }

      .payment {
        .payment-btn {
          font-size: 16px;
        }
      }
    }
  }
}
