@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold";
  src: url("../fonts/Sofia_Pro_Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.businessAnalyst-main {
  // Banner Section
  .top-page-ba {
    background: url("../images/courses-main1.png") no-repeat center center;
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-page-container-ba {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 0px;
    color: #fff;
  }
  .lokem-headers-ba {
    font-family: "Lokem-Lighter";
    font-size: 60px;
  }

  .first-page-heading-ba {
    padding-bottom: 0px;
  }
  .banner-heading-ba {
    color: #fff;
  }
  .training-dates-button-section-ba {
    padding-bottom: 10px;
  }
  .book-btn-position-ba {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -42px;
  }
  .book-place-button-ba {
    padding: 18px;
    padding-left: 30px;
    padding-right: 30px;
    background: #cba53c;
    color: #fff;
    font-size: 25px;
    border: none;
    border-radius: 5px;
    font-family: Lokem-Lighter;
    cursor: pointer;
  }

  // Section 2
  .training-overview-main-ba {
    background: #fff;
  }

  .train-your-main-ba {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    /*background-image: url(../images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d);*/
    background-repeat: no-repeat;
    background-position: center;
  }

  .max-width-div-ba {
    /* max-width: 1200px; */
    width: 100%;
    display: inline-block;
  }

  .tyt-heading-main-ba {
    width: 100%;
    padding-bottom: 25px;
  }

  .tyt-heading-ba {
    text-align: left;
  }

  .tyt-heading-text-ba {
    font-size: 45px;
    font-family: Lokem-Medium;
  }

  .overview-cols-main-ba {
    width: 100%;
    display: inline-flex;
  }

  .ba-overview-col1-ba {
    width: 49%;
    text-align: left;
    margin-right: 1%;
  }

  .tyt-text-ba {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 25px;
    padding-left: 5px;
  }

  .each-para-div-ba {
    display: inline-flex;
    padding-bottom: 25px;
  }

  .ba-overview-col2-ba {
    width: 49%;
    margin-left: 1%;
    text-align: left;
  }

  .to-margin-ba {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
  }

  .to-img-ba {
    width: 30%;
    text-align: center;
  }

  .course-overview-image1-ba {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
  }

  // Section 3
  .tobj-main-ba {
    width: 80%;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #787776;
  }

  .tobj-heading-ba {
    text-align: center !important;
    font-family: Lokem-Medium;
    color: #f9dd8e;
  }

  .tyt-heading-text-ba {
    font-size: 45px;
    font-family: Lokem-Medium;
  }

  .tobj-sub-ba {
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 21px;
    font-family: Lokem-Medium;
    color: #fff;
  }

  .tobj-col-main-ba {
    width: 100%;
  }

  .tobj-color-ba {
    color: #fff;
    margin-bottom: 5px;
  }

  .tyt-text-ba {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 25px;
    padding-left: 5px;
  }

  .tobj-row-ba {
    display: inline-flex;
    width: 100%;
    padding-bottom: 40px;
  }

  .tobj-col1-ba {
    width: 44%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .tyt-sub-heading-ba {
    font-size: 25px;
    color: #d3b155;
    font-family: "Lokem-Lighter";
  }

  .tobj-img-ba {
    width: 80px;
    height: 90px;
  }

  .each-para-div-ba {
    display: inline-flex;
    padding-bottom: 25px;
  }

  // Section 4

  .overview-col1-ba {
    width: 75%;
    text-align: left;
    padding-top: 25px;
  }

  .padding-bottom-para-ba {
    padding-bottom: 40px !important;
  }

  .overview-col2-ba {
    float: right;
    padding-left: 30px;
  }
  .training-dates-main-ba {
    background: url(../images/our3cBG.png),
      linear-gradient(to right, #ebd9a5, #e4cd8d);
    background-position: right;
    width: 260px;
    text-align: center;
    border-radius: 10px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 35px;
    margin-top: 45px;
  }

  .training-dates-text-ba {
    color: #000;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: Lokem-Medium;
    font-size: 30px;
  }

  .location-online-ba {
    padding-bottom: 20px;
  }
  .package-includes-ba {
    padding-bottom: 50px;
  }
  .booknow-style-ba {
    padding-bottom: 45px;
  }
  .training-dates-button-section-ba {
    padding-bottom: 10px;
  }
  .lf-use-code-main-ba {
    font-family: Lokem-Lighter;
    font-size: 16px;
    padding-bottom: 5px;
  }
  .lf-coupon-code-ba {
    color: #161411;
    font-family: "Lokem-Headings";
  }
  .lokem-button-ba {
    border: none !important;
    border-radius: 5px !important;
  }
  
  .float-initial-ba {
    float: initial !important;
  }
  .course-overview-image2-ba {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    float: left;
    margin-top: 20px;
    object-fit: cover;
  }
  .fac-course-ba {
    color: #e9b004;
    padding-top: 60px;
    padding-bottom: 10px;
    font-family: Lokem-Medium;
  }
  .modules-covered-ba div {
    border-bottom: 1px solid #e8cd77;
    padding-top: 10px;
  }
  .mc-heading-ba {
    padding-bottom: 5px;
    border: none !important;
    color: #e9b004;
    font-family: Lokem-Medium;
  }

  .booknow-style-ba {
    padding-bottom: 45px;
  }

  // Section 5
  .po-journey-main {
    display: flex;
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background: #fff;
    position: relative;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .po-Infograph {
    width: 100%;
  }

  @media (max-width: 995px) {
    .top-page-ba {
      background: url("../images/courses-main1.png") no-repeat center center;
      min-height: 390px;
      background-size: cover;
    }
    .first-page-container-ba {
      text-align: center;
      margin-left: 3%;
      margin-right: 3%;
      padding-top: 60px;
      color: #fff;
    }
    .lokem-headers-ba {
      font-family: "Lokem-Lighter";
      font-size: 40px;
    }
    .first-page-heading-ba {
      font-size: 40px;
    }
    .book-btn-position-ba {
      bottom: -30px;
    }
    .book-place-button-ba {
      padding: 8px;
      padding-left: 18px;
      padding-right: 18px;
      background: #cba53c;
      color: #fff;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      font-family: Lokem-Lighter;
    }
    .training-overview-main-ba {
      background: #fff;
    }
    .train-your-main-ba {
      display: inline-block;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: center;
    }
    .max-width-div-ba {
      max-width: initial;
      width: 100%;
      display: inline-block;
    }
    .tyt-heading-main-ba {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .padding-top-ba {
      padding-top: 20px !important;
    }
    .tyt-heading-text-ba {
      font-size: 30px;
      font-family: Lokem-Medium;
    }
    .tyt-heading-ba {
      text-align: center;
    }
    .overview-cols-main-ba {
      width: 100%;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
    }
    .ba-overview-col1-ba {
      width: 100%;
      text-align: center;
      margin-right: 0;
    }
    .ba-overview-col2-ba {
      width: 100%;
      margin-left: 0;
      text-align: center;
    }
    .overview-cols-main-ba {
      width: 100%;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
    }
    .to-img-ba {
      width: 100%;
      text-align: center;
    }
    .course-overview-image1-ba {
      float: initial;
      width: 300px;
      height: 300px;
      border-radius: 50%;
    }
    .tobj-row-ba {
      display: initial;
      width: 100%;
      padding-bottom: 40px;
      text-align: center;
    }
    .tobj-col1-ba {
      width: 94%;
      margin-left: 3%;
      margin-right: 3%;
    }
    .tyt-sub-heading-ba {
      font-size: 22px;
    }
    .overview-col1-ba {
      width: 100%;
      text-align: center;
    }
    .overview-col2-ba {
      width: 100%;
      margin-left: 0;
    }
    .training-dates-main-ba {
      background: url(../images/our3cBG.png),
        linear-gradient(to right, #ebd9a5, #e4cd8d);
      background-position: right;
      /* background: #fcdf8f; */
      width: auto;
      text-align: center;
      border-radius: 10px;
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 35px;
      margin-top: 15px;
    }
    
    .course-overview-image2-ba {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      float: initial;
      margin-top: 20px;
    }

    .po-journey-main-ba {
      display: flex;
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      background: #fff;
      position: relative;
      padding-bottom: 0px !important;
      padding-top: 0px !important;
    }
  }
}
