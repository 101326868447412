@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.SimiJourney-main {
  .bottom-space {
    margin-bottom: 30px;
  }

  .train-your-main-semi {
    width: 50% !important;
    padding-left: 25% !important;
    padding-right: 25% !important;
    margin: auto;
    max-width: 750px;
  }
  .simi-text {
    font-family: "Lokem-Headings";
    font-size: 42px;
    text-align: left;
  }
  .quote-text-semi {
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: Lokem-Lighter;
    font-size: 20px;
    text-align: left;
    border-left: 5px solid #cba53c;
    padding-left: 20px;
  }
  .quotes {
    font-size: 24px;
    font-family: Lokem-Lighter;
  }
  .tyt-paras-semi {
    width: 100%;
  }
  .flex-dir-semi {
    flex-direction: row-reverse;
  }
  .tyt-sub-heading-semi {
    font-size: 28px;
    color: #000;
    font-family: "Lokem-Medium";
    margin-bottom: 10px;
  }
  .bottom-space-semi {
    margin-bottom: 30px;
  }
  .secs-tyt-cols-semi {
    width: 100%;
    text-align: left;
  }
  .tyt-text-semi {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
  }
  .img-simi-main {
    width: 500px;
    margin: auto;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .img-simi {
    width: 100%;
  }

  @media (max-width: 995px) {
    .train-your-main-semi {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
    .simi-text {
      font-size: 25px;
    }

    .tyt-paras {
      width: 100%;
      display: inline-block;
    }
    .tyt-text-semi {
      text-align: left;
      font-size: 17px;
    }
    .img-simi-main {
      width: auto;
    }
    .tyt-sub-heading-semi {
      font-size: 25px;
      color: #000;
      font-family: "Lokem-Medium";
      text-align: left;
    }
  }
}
