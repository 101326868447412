@font-face {
    font-family: "Lokem-Headings";
    src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Lighter";
    src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Medium";
    src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Charity-Font";
    src: url("../fonts/Pristina-font.ttf") format("truetype");
    font-weight: 600;
  }
 .main-adv{
.adv-main {
    background: #c1b694;
    width: 100%;
    
}
.main {
    display: inline-flex;
    width: 100%;
    align-items: center;
    
}
.adv-col1 {
    width: 60%;
    text-align: left;
    font-family: Lokem-Lighter;
    margin-left:15px;
    color: white;
    font-size: 23px;
}
a{
    text-decoration: none;
}
.adv-col3 {
    width: 40%;
    margin-right: 65px;
    text-align: right;
}
.timer-main {
    display: inline-flex;
    align-items: center;
}
.timer-button {
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
}
.adv-time {
    margin-right: 20px;
    font-size: 21px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0px;
}
.adv-days {
    font-size: 15px;
    text-align: left;
    margin-bottom: 10px;
}
.lokem-button {
    border: none !important;
    border-radius: 5px !important;
}
.getStarted {
    padding: 8px;
    padding-left: 24px;
    padding-right: 24px;
    background: #000;
    color: #fff;
    font-size: 15px;
    border: none;
    border-radius: 12px;
    font-family: Lokem-Medium;
    margin: 0;
    cursor: pointer;
}
.flex-cols{
    display: inline-flex;
}

@media (max-width: 995px) {
    .adv-main {
        background: #c1b694;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        
    }
   
    .adv-col1 {
        width: 65%;
        text-align: left;
        font-family: Lokem-Lighter;
        margin-left: 3%;
        color: white;
        font-size: 12px;
    }
    .adv-col3 {
        width: 30%;
        text-align: right;
        margin-right: 20px;
        display: inline-block;
    }
    .flex-cols {
        display: none;
    }
    .button-main {
        width: 100%;
    }
    .getStarted {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        background: #000;
        color: #fff;
        font-size: 10px;
        border: none;
        border-radius: 10px;
        font-family: Lokem-Medium;
        margin-top: 0;
        margin-bottom: 0;
    }
}
 }