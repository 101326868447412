@font-face {
    font-family: "Lokem-Headings";
    src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Lighter";
    src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Medium";
    src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Charity-Font";
    src: url("../fonts/Pristina-font.ttf") format("truetype");
    font-weight: 600;
  }


.trainee-main{
.paymentMainSection {
    width: 50%;
    margin-top: 20px;
    text-align: center;
    display: inline-flex;
    margin-left: 25%;
    margin-right: 25%;
    border: 2px solid #737373;
    border-radius: 3px;
    padding-left: 35px;
    padding-right: 20px;
}
.leftPaymentSection {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 65%;
    float: right;
    text-align: center;
    border-right: 1px solid #dfe0e1;
}
.leftPaymentHeadings {
    text-align: left;
    margin-bottom: 12px;
    font-size: 30px;
    font-family: Lokem-Headings;
    opacity: 0.6;
}
.traineeFieldDiv {
    width: 100%;
    padding-top: 5px;
    text-align: left;
    margin-bottom: 14px;
}
#payment-form input[type="text"] {
    height: 40px;
    font-size: 16px;
    /*padding: 10px; /* Example value for --p-spacing3 */
    background-color: white; /* Example value for --colorBackground */
    border-radius: 5px; /* Example value for --borderRadius */
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid #dcdcdc; /* Example value for --p-colorBackgroundDeemphasize10 */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
.leftFieldsWidth {
    width: 95%;
}
.contactSelect {
    width: 95%;
    padding-left: 6px;
    border-radius: 3px;
    height: 45px;
    font-size: 16px;
    padding: var(--p-spacing3);
    background-color:#fff;;
    border-radius:5px;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
}
.hlineMain {
    width: 100%;
}

.hline {
    border-top: 1px solid #625c5c;
    text-align: center;
    margin-top: 23px;
    margin-bottom: 23px;
}
.scheduleMain {
    width: 100%;
}
.leftPaymentHeadings {
    text-align: left;
    margin-bottom: 12px;
    font-size: 30px;
    font-family: Lokem-Headings;
    opacity: 0.6;
}
.nonRefundable {
    font-size: 16px;
    padding-left: 5px;
    font-family: Lokem-Headings;
    color: #c3a555;
}
.typeOfPaymentDiv {
    width: 100%;
    padding-top: 5px;
    text-align: left;
    margin-bottom: 5px;
    font-size: 17px;
    color: #000;
}
.typeOfRadioField {
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.typeOfPaymentLabel {
    padding-left: 6px;
    font-family: Lokem-Lighter;
    cursor: pointer;
}

.traineeMainSection {
    width: 100%;
}
.leftPaymentHeadings {
    text-align: left;
    margin-bottom: 12px;
    font-size: 30px;
    font-family: Lokem-Headings;
    opacity: 0.6;
}
.applyCouponDiv {
    display: inline-flex;
    width: 100%;
    font-size: 19px;
    font-family: Lokem-Headings;
}
.totalText {
    width: 60%;
    text-align: left;
}
.applyLinkDiv {
    width: 40%;
    padding-top: 5px;
}
.rightPaymentSection {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 32%;
    float: left;
    text-align: left;
    padding-left: 3%;
}
.includedInPurchase {
    width: 100%;
}
.rightPaymentHeadings {
    padding-top: 10px;
    text-align: left;
    margin-bottom: 15px;
    font-size: 20px;
    opacity: 0.6;
    font-family: Lokem-Headings;
}
.trainingIncludes {
    margin: 0;
    padding-left: 20px;
    font-family: "Lokem-Lighter";
    font-size: 15px;
    letter-spacing: 1px;
    opacity: 1;
    line-height: 25px;
    color: #000;
}
.paymentAmountMain {
    width: 100%;
    margin-top: 20px;
}
.amountHeading {
    text-align: left;
    margin-bottom: 5px;
    font-size: 22px;
    opacity: 0.6;
    font-family: Lokem-Headings;
}
.totalPayment {
    display: inline-flex
;
    width: 100%;
    font-size: 17px;
    font-family: Lokem-Headings;
    opacity: 0.6;
    margin-top: 10px;
    color: #000;
}
.totalHeading {
    margin-bottom: 10px;
}
.lf-coupon-code {
    animation: blinker 1s linear infinite;
    color: #c3a555;
}
.makePaymentDiv {
    margin-top: 5px;
    text-align: left;
    margin-bottom: 6px;
    padding-bottom: 2%;
    width: 100%;
}
.proceedPaymentBtn {
    background-color: #c3a555;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    color: #ffffff;
    border: none;
    text-align: left;
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    font-family: Lokem-Lighter;
}
input[type="radio"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #c3a555;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.applyLinkDiv a {
    color: #cfa655 !important;
    font-family: Lokem-Lighter;
}
.orderSummary {
    margin-top: 12px;
    text-align: center;
    font-size: 32px;
    font-family: Lokem-Headings;
    opacity: 0.6;
}

.hlineMainMobile {
    display: none;
}
.couponField {
    height: 40px;
    border: 1px solid #bbb6b6;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 16px;
    margin-bottom: 0px;
    padding-left: 10px;
    width: 80%;
}
.errorText{
    color: #df1b41;
    font-weight: bold;
    font-size: 15px;
    font-family: Lokem-Headings;
    text-align: left;
    margin-top: 5px;
    margin-left: 2px;
}
#payment-form{
    margin-bottom: 16px;
}
@media (max-width: 995px) {
    .orderSummary {
        margin-top: 12px;
        text-align: center;
        font-size: 28px;
        font-family: Lokem-Headings;
        opacity: 0.6;
    }
    .leftPaymentHeadings {
        text-align: left;
        margin-bottom: 12px;
        font-size: 30px;
        font-family: Lokem-Headings;
        opacity: 0.6;
    }
    .hlineMainMobile {
        width: 100%;
        text-align: center;
        display: none;
    }
    .leftFieldsWidthMobile {
        width: 70%;
        border-top: 1px solid #625c5c;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 15%;
        margin-right: 15%;
    }
    .paymentMainSection {
        display: inline-block;
        width: 94%;
        margin-top: 0;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        border: none;
        border-radius: 3px;
        padding-left: 6%;
        padding-right: 0;
    }
    .leftPaymentSection {
        padding-top: 10px;
        padding-bottom: 0px;
        width: 100%;
        float: right;
        text-align: center;
        border-right: none;
    }
    .traineeMainSection {
        width: 100%;
    }
    .leftPaymentHeadings {
        text-align: left;
        margin-bottom: 12px;
        font-size: 30px;
        font-family: Lokem-Headings;
        opacity: 0.6;
    }
    .traineeFieldDiv {
        width: 100%;
        padding-top: 5px;
        text-align: left;
    }
    .leftFieldsWidth {
        width: 95%;
    }
    .hlineMain {
        width: 100%;
        display: none;
    }
    .leftFieldsWidth {
        width: 95%;
    }
    .hline {
        border-top: 1px solid #625c5c;
        text-align: center;
        margin-top: 23px;
        margin-bottom: 23px;
    }
    .scheduleMain {
        width: 100%;
    }
    .typeOfPaymentDiv {
        width: 100%;
        padding-top: 5px;
        text-align: left;
        margin-bottom: 5px;
        font-size: 17px;
    } 
    .typeOfPaymentLabel {
        padding-left: 6px;
        font-family: Lokem-Lighter;
        cursor: pointer;
    }
    .applyCouponDiv {
        display: inline-flex;
        width: 100%;
        font-size: 19px;
        font-family: Lokem-Headings;
    }
    .totalText {
        width: 60%;
    }
    .rightPaymentSection {
        padding-top: 10px;
        padding-bottom: 20px;
        width: 100%;
        float: left;
        text-align: left;
        padding-left: 0;
    }
    .includedInPurchase {
        width: 100%;
    }
    .rightPaymentHeadings {
        padding-top: 10px;
        text-align: left;
        margin-bottom: 15px;
        font-size: 20px;
        opacity: 0.6;
        font-family: Lokem-Headings;
    }
    .trainingIncludes {
        margin: 0;
        padding-left: 20px;
        font-family: "Lokem-Lighter";
        font-size: 15px;
        letter-spacing: 1px;
        opacity: 1;
        line-height: 25px;
    }
    .paymentAmountMain {
        width: 100%;
        padding-top: 15px;
    }
    .amountHeading {
        text-align: left;
        margin-bottom: 5px;
        font-size: 22px;
        opacity: 0.6;
        font-family: Lokem-Headings;
    }
    .totalPayment {
        display: inline-flex;
        width: 100%;
        font-size: 19px;
        font-family: Lokem-Headings;
        opacity: 0.6;
    }
    .totalHeading {
        margin-bottom: 10px;
    }
    .makePaymentDiv {
        margin-top: 5px;
        text-align: left;
        margin-bottom: 6px;
        padding-bottom: 2%;
        width: 100%;
    }
    .proceedPaymentBtn {
        background-color: #c3a555;
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;
        color: #ffffff;
        border: none;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        font-weight: normal;
        font-family: Lokem-Lighter;
    }
}
}