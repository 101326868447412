@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.ProjectManagerTraining-main {
  // Banner Section
  // Banner Section
  .top-page {
    background: url("../images/courses-main1.png") no-repeat center center;
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .first-page-container {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 0px;
    color: #fff;
  }

  .lokem-headers {
    font-family: "Lokem-Lighter";
    font-size: 60px;
  }

  .first-page-heading {
    padding-bottom: 0px;
  }

  .banner-heading {
    color: #fff;
  }

  .training-dates-button-section {
    padding-bottom: 10px;
  }

  .book-btn-position {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -42px;
  }

  .book-place-button {
    padding: 18px;
    padding-left: 30px;
    padding-right: 30px;
    background: #cba53c;
    color: #fff;
    font-size: 25px;
    border: none;
    border-radius: 5px;
    font-family: Lokem-Lighter;
    margin-top: 15px;
    cursor: pointer;
  }

  /* Section 2 */
  .training-overview-main {
    background: #fff;
  }

  .train-your-main {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    /* background-image: url(../media/images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d); */
    background-repeat: no-repeat;
    background-position: center;
  }

  .max-width-div {
    /* max-width: 1200px; */
    width: 100%;
    display: inline-block;
  }

  .overview-cols-main {
    width: 100%;
    display: inline-flex;
  }

  .overview-col1 {
    width: 65%;
    text-align: left;
    padding-top: 25px;
  }

  .tyt-heading-main {
    width: 100%;
    padding-bottom: 25px;
  }

  .tyt-heading {
    text-align: left;
  }

  .tyt-heading-text {
    font-size: 45px;
    font-family: Lokem-Medium;
  }

  .tyt-text {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 16px;
    line-height: 25px;
    padding-left: 5px;
  }

  .each-para-div {
    display: inline-flex;
    padding-bottom: 25px;
  }

  .course-overview-image1 {
    float: right;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /* Section 3 */
  .tobj-main {
    width: 80%;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #fcdf8f;
  }

  .tobj-heading {
    text-align: center !important;
    color: black !important;
    font-family: Lokem-Medium;
  }

  .tobj-sub {
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 21px;
    font-family: Lokem-Medium;
  }

  .tobj-col-main {
    width: 100%;
  }

  .tobj-row {
    display: inline-flex;
    width: 100%;
    padding-bottom: 40px;
  }

  .tobj-col1 {
    width: 44%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .tobj-color {
    margin-bottom: 5px;
  }

  .tyt-sub-heading {
    font-size: 25px;
    color: #fff;
    font-family: "Lokem-Lighter";
  }

  .tobj-img {
    width: 80px;
    height: 90px;
  }

  /* Section 4 */

  .padding-bottom-para {
    padding-bottom: 40px !important;
  }

  .overview-col2 {
    float: right;
    padding-left: 30px;
  }

  .training-dates-main {
    background: url(../images/our3cBG.png),
      linear-gradient(to right, #ebd9a5, #e4cd8d);
    background-position: right;
    width: 260px;
    text-align: center;
    border-radius: 10px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 75px;
    margin-top: 115px;
  }

  .training-dates-text {
    color: #000;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: Lokem-Medium;
    font-size: 30px;
  }

  .location-online {
    padding-bottom: 20px;
  }

  .package-includes {
    padding-bottom: 50px;
  }

  .lf-use-code-main {
    font-family: Lokem-Lighter;
    font-size: 16px;
    padding-bottom: 5px;
  }

  .lf-coupon-code {
    color: #161411;
    font-family: "Lokem-Headings";
  }

  .lokem-button {
    border: none !important;
    border-radius: 5px !important;
  }

  .float-initial {
    float: initial !important;
  }

  .course-overview-image2 {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    float: left;
    margin-top: 20px;
    object-fit: cover;
  }

  .fac-course {
    color: #e9b004;
    padding-top: 60px;
    padding-bottom: 10px;
    font-family: Lokem-Medium;
  }

  .modules-covered div {
    border-bottom: 1px solid #e8cd77;
    padding-top: 10px;
  }

  .mc-heading {
    padding-bottom: 10px;
    border: none !important;
    color: #e9b004;
    font-family: Lokem-Medium;
  }

  .booknow-style {
    padding-bottom: 40px;
  }

  @media (max-width: 995px) {
    .top-page {
      background: url("../images/courses-main1.png") no-repeat center center;
      min-height: 390px;
      background-size: cover;
    }
    .first-page-container {
      text-align: center;
      margin-left: 3%;
      margin-right: 3%;
      padding-top: 60px;
      color: #fff;
    }
    .lokem-headers {
      font-family: "Lokem-Lighter";
      font-size: 40px;
    }
    .first-page-heading {
      font-size: 40px;
    }
    .book-btn-position {
      bottom: -30px;
    }

    .train-your-main {
      display: inline-block;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: center;
    }
    .max-width-div {
      max-width: initial;
      width: 100%;
      display: inline-block;
    }
    .overview-cols-main {
      width: 100%;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
    }
    .overview-col1 {
      width: 100%;
      text-align: center;
    }
    .tyt-heading-main {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .tyt-heading-text {
      font-size: 30px;
      font-family: Lokem-Medium;
    }
    .tyt-heading {
      text-align: center;
    }
    .overview-col2 {
      width: 100%;
      margin-left: 0;
    }
    .course-overview-image1 {
      float: initial;
      width: 300px;
      height: 300px;
      border-radius: 50%;
    }
    .tobj-row {
      display: initial;
      width: 100%;
      padding-bottom: 40px;
      text-align: center;
    }
    .tobj-col1 {
      width: 94%;
      margin-left: 3%;
      margin-right: 3%;
    }
    .tyt-sub-heading {
      font-size: 22px;
    }
    .training-overview-main {
      background: #fff;
    }
    .training-dates-main {
      background: url(../images/our3cBG.png),
        linear-gradient(to right, #ebd9a5, #e4cd8d);
      background-position: right;
      /* background: #fcdf8f; */
      width: auto;
      text-align: center;
      border-radius: 10px;
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 35px;
      margin-top: 15px;
    }

    .book-place-button {
      padding: 8px;
      padding-left: 18px;
      padding-right: 18px;
      background: #cba53c;
      color: #fff;
      font-size: 20px;
      border: none;
      border-radius: 5px;
      font-family: Lokem-Lighter;
    }
  }
}
