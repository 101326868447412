.contact-container {
    background-color: #f5f5f5;

    .title-bar {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px 23px;
      margin-bottom: 20px;
  
      .menu-icon {
        font-size: 24px;
        margin-right: 10px;
        cursor: pointer;
        margin-left: 55px;

        i{
            font-size: 35px;
        }
      }
  
      h1 {
        font-size: 24px;
        font-family: 'Lokem-Medium';
        color: #000;
        margin: 0;
        margin-left: 5px;
        }
    }

    .main-page{
        background-color: #f5f5f5;
        min-height: 100vh;
    }
}