@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.viewPrograms-main {
  .top-page {
    background: url(../images/aboutus-banner.png);
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
  }
  .first-page-container-vp {
    text-align: center;
    font-size: 50px;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 0;
    color: #fff;
    width: 100%;
  }

  .lokem-headers {
    font-family: "Lokem-Lighter";
    font-size: 60px;
  }

  .banner-heading {
    color: #fff;
  }
  .training-dates-button-section-vp {
    padding-top: 30px !important;
  }
  .lokem-button {
    border: none !important;
    border-radius: 5px !important;
  }
  .get-in-touch-vp {
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background: #c9a328;
    color: #fff;
    font-size: 22px;
    border: none;
    border-radius: 5px;
    font-family: Lokem-Lighter;
    text-decoration: none;
  }

  .train-your-main-vp {
    width: 80% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
  }
  .row-vp {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .tyt-text {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 18px;
  }
  .foundation-fieldset {
    border-color: #aecacd;
  }
  fieldset {
    display: flex;
    margin: 0;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    border: dotted;
    border-radius: 5px;
  }
  .foundation {
    font-size: 22px;
    background: #0097a7;
    border-radius: 5px;
    padding: 8px;
    color: #fff;
    font-family: "Lokem-Headings";
  }
  .column {
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    text-align: left;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
    border: 2px solid #e9e5da;
    border-radius: 5px;
  }
  .column:hover {
    background-color: #e0e1e3; /* Subtle background color change */
  }
  .tyt-heading-text-vp {
    font-size: 24px;
    font-family: Lokem-Medium;
    margin-bottom: 10px;
    color: #c9a328;
    text-align: left;
  }
  .each-para-div {
    padding-bottom: 20px;
    text-align: left;
    line-height: 24px;
  }
  .tyt-sub-heading-vp {
    font-size: 20px;
    color: #000;
    font-family: "Lokem-Medium";
    margin-bottom: 10px;
  }
  .intermediate-fieldset {
    border-color: #bf96ab;
  }
  .intermediate {
    font-size: 22px;
    background: #ba146c;
    border-radius: 5px;
    padding: 8px;
    color: #fff;
    font-family: "Lokem-Headings";
  }
  .advanced-fieldset {
    border-color: #b2c5aa;
  }
  .advanced {
    font-size: 22px;
    background: #6aa84f;
    border-radius: 5px;
    padding: 8px;
    color: #fff;
    font-family: "Lokem-Headings";
  }
  .contact-text {
    text-align: center;
    font-size: 28px;
    padding-bottom: 10px;
    color: #c9a328;
    font-family: "Lokem-Medium";
}
.dont-worry {
  color: #000;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 23px;
  font-family: "Lokem-Medium";
}
.tyt-heading-line-main-vp {
  margin-bottom: 30px;
}
.tyt-heading-line-vp {
  width: 60px;
  border: 1.9px solid #d3b155;
  background: #d3b155;
  float: left ;
  margin-left: 3px;
}




.tyt-text-heading-VP {
  text-align: left;
}

.tyt-heading-line-main-VP {
  margin-bottom: 30px;
}




#business-form input[type="text"],
select,
textarea {
  width: 100%;
  height: 32px;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  padding-left: 6px;
  border-radius: 3px;
  vertical-align: middle;
}

#business-form div {
  margin-bottom: 16px;
}

  
  .book-call-container {
  border-radius: 5px;
  /* background-color: #f2f2f2; */
  /* padding: 20px; */
  text-align: left;
}
  .contactMsg {
  text-align: center;
  color: #d5a215;
}
label {
  font-family: Lokem-Medium;
}
.book-call-btn{
  background-color: #d3b155;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Lokem-Medium;
    font-size: 18px;
}
.btn-main {
  text-align: center;
}
  @media (max-width: 995px) {
    .top-page {
      min-height: 390px;
  }
    .lokem-headers {
      font-family: "Lokem-Lighter";
      font-size: 40px;
  }
  .training-dates-button-section-vp {
    padding-top: 30px;
    padding-bottom: 60px;
}
.banner-heading {
  color: #fff;
}
.get-in-touch-vp {
  padding: 8px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  font-family: Lokem-Lighter;
}
.train-your-main-vp {
  width: 90% !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center;
}
.row-vp {
  display: inline-block;
  text-align: center;
  justify-content: center;
}
fieldset {
  padding: 0;
  display: inline-block;
  margin: 0;
  transition: 0.5s;
  padding-top: 10px;
  padding-bottom: 25px;
  border: dotted;
  border-radius: 5px;
}
.column {
  padding-left: 25px;
  padding-right: 20px;
  width: 80%;
  text-align: left;
  height: auto;
  padding-top: 1px;
  padding-bottom: 20px;
  border: 2px solid #e9e5da;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 15px;
}
.embed-main {
  background: white!important;
  color: #050505;
}
.tyt-heading-text-vp {
  text-align: center;
}
  
  .tyt-heading-line-vp {
    width: 60px;
    border: 1.9px solid #d3b155;
    background: #d3b155;
    margin-left: auto;
    float: initial;
}
}
}