@font-face {
    font-family: "Lokem-Headings";
    src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Lighter";
    src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Medium";
    src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
    src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
    src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
    font-weight: 600;
  }
  
  :root {
    --leftMargin: 15px;
  }
  .adv-main {
    background: #c1b694;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .main {
    width: 100%;
    align-items: center;
  }
  
  .adv-col1 {
    width: 90%;
    text-align: left;
    font-family: Lokem-Lighter;
    margin-left: var(--leftMargin);
    color: white;
    font-size: 23px;
  }
  
  .adv-col2 {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
  }
  .adv-col3 {
    width: 40%;
    margin-right: 65px;
    text-align: right;
  }
  
  .flex-cols {
    display: inline-flex;
    font-family: Lokem-Medium;
    color: white;
  }
  
  .adv-time {
    margin-right: 20px;
    font-size: 21px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  .adv-days {
    font-size: 15px;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .adv-copy {
    width: 15%;
  }
  .course-titles {
    display: inline-flex;
    font-size: 24px;
    font-family: Lokem-Medium;
    background: #5d4b17;
    color: white;
    border-radius: 15px;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .getStarted {
    padding: 8px;
    padding-left: 24px;
    padding-right: 24px;
    background: #000;
    color: #fff;
    font-size: 15px;
    border: none;
    border-radius: 12px;
    font-family: Lokem-Medium;
    margin: 0;
    cursor: pointer;
  }
  
  .mobile-show {
    display: none !important;
  }
  .desktop-show {
    display: inline-block;
  }
  .our-students {
    font-size: 13px;
    margin-bottom: 6px;
  }
  .timer-button {
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
  }
  .timer-main {
    display: inline-flex;
    align-items: center;
  }
  
  .use-code-main {
    font-family: Lokem-Lighter;
  }
  .limited-offer {
  }
  .coupon-code {
    color: #4d4735;
  }
  
  @media (max-width: 995px) {
    .flex-cols {
      display: none;
    }
    .getStarted {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      background: #000;
      color: #fff;
      font-size: 10px;
      border: none;
      border-radius: 10px;
      font-family: Lokem-Medium;
      margin-top: 0;
      margin-bottom: 0;
    }
    .adv-col1 {
      width: 65%;
      text-align: left;
      font-family: Lokem-Lighter;
      margin-left: 3%;
      color: white;
      font-size: 12px;
    }
    .adv-col3 {
      width: 30%;
      text-align: right;
      margin-right: 20px;
      display: inline-block;
    }
    .adv-main {
      background: #c1b694;
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .button-main {
      width: 100%;
    }
  }
  