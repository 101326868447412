@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.trainees-container {
  padding: 20px;
  font-family: "Lokem-Medium", sans-serif;

  .trainees-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 1.5rem;
      color: #333;
    }
  }

  .trainees-wrapper {
    background-color: #f5f5f5;
    padding: 1rem;

    .filter-section {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        font-size: 1rem;
        color: #000;
      }

      select {
        width: 200px;
        padding: 5px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
  }

  .trainees-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;

    .thread {
      height: 50px;
    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }

    th {
      background-color: #dbdbdb;
      font-family: "Lokem-Medium", sans-serif;
      font-size: 1rem;
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f4f4f4;
        }
      }

      .view-btn {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
      }

      .no-data {
        text-align: center;
        font-style: italic;
        color: #999;
      }
    }
  }
}

.trainees-container {
  padding: 20px;
  font-family: "Lokem-Medium", sans-serif;

  .trainees-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 1.5rem;
      color: #333;
    }
  }

  .trainees-wrapper {
    background-color: #f5f5f5;
    padding: 1rem;

    .filter-section {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        font-size: 1rem;
        color: #000;
      }

      select {
        width: 200px;
        padding: 5px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
  }

  .trainees-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;

    .thread {
      height: 50px;
    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }

    th {
      background-color: #dbdbdb;
      font-family: "Lokem-Medium", sans-serif;
      font-size: 1rem;
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f4f4f4;
        }
      }

      .view-btn {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
      }

      .no-data {
        text-align: center;
        font-style: italic;
        color: #999;
      }
    }
  }

  @media (max-width: 768px) {
    .trainees-wrapper {
      overflow-x: auto;
    }

    .trainees-table {
      width: max-content;
    }

    th,
    td {
      font-size: 0.9rem;
      padding: 8px;
    }

    th {
      font-size: 1rem;
    }

    .filter-section {
      flex-direction: row;
      align-items: flex-start;

      label,
      select {
        width: 100%;
      }
    }
  }

  @media (max-width: 480px) {
    .trainees-header {
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
    }

    .trainees-table {
      th,
      td {
        font-size: 1.2rem;
        padding: 6px;
        width: 200px;
      }
    }

    .filter-section {
      label,
      select {
        font-size: 0.9rem;
      }

      select {
        width: 800px !important;
        margin-left: -1.5rem;
      }
    }

    .view-btn {
      font-size: 1rem;
    }
  }
}
