@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
body {
  margin: 0;
  font-family: Lokem-Headings !important;
  overflow-x: hidden !important;
  line-height: initial !important;
  color: #000 !important;
  background-color: #fff !important;
}

.footer-main {
  .callbackFormMain {
    margin-left: 0;
    margin-right: 0;
  }
  .contactFieldDiv {
    width: 33% !important;
    margin-right: 1% !important;
  }
  .footer {
    background: #000;
    color: #fff;
    padding-top: 0;
  }
  .footer-content {
    padding-left: 50px;
    padding-right: 50px;
  }
  .menu-items-footer-container {
    text-align: center;
    width: 100%;
    display: inline-flex;
    padding-top: 30px;
    border-bottom: 1px solid #cba53c;
  }
  .menu-items-footer {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 70%;
    float: left;
    text-align: left;
  }
  .social-media-container {
    width: 30%;
    float: right;
    text-align: right;
  }
  .social-media-icons {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .menu-items-footer li {
    display: inline;
    padding-right: 30px;
    font-weight: normal;
    font-family: Lokem-Lighter;
  }
  .social-media-icons li {
    display: inline;
    padding-right: 20px;
    font-weight: normal;
    font-family: Lokem-Lighter;
  }

  .footer,
  li,
  a {
    color: #fff !important;
    text-decoration: none;
  }
  .social-icon {
    font-size: 28px !important;
    color: #fff !important;
  }
  .fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .logo-content-footer-conatiner {
    display: inline-block;
    width: 100%;
    padding-top: 30px;
  }
  .logo-content-footer {
    width: 75%;
    float: left;
    font-family: Lokem-Lighter;
  }
  .callBackMain {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .lokem-button {
    border: none !important;
    border-radius: 5px !important;
  }

  .callBackMsg {
    margin-top: 12px;
    font-family: "Lokem-Medium";
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    color: #cba53c;
    font-size: 18px;
  }
  .logo-img-footer-container {
    float: left;
  }
  .logo-img-footer {
    width: 200px;
    cursor: pointer;
    margin-top: 30px;
  }
  .logo-text-footer {
    display: inline-block;
    width: 60%;
    margin-left: 30px;
  }
  .footer p {
    padding-bottom: 5px;
  }
  .address-footer {
    float: right;
    width: 25%;
    text-align: right;
    font-family: Lokem-Lighter;
  }
  .copyRightText {
    width: 100%;
    text-align: center;
    font-family: Lokem-Lighter;
    padding-bottom: 7px;
  }
  .error-message {
    color: #df1b41;
    font-weight: bold;
    font-size: 15px;
    font-family: Lokem-Headings;
    text-align: left;
    margin-top: 5px;
    margin-left: 2px;
  }
  @media (max-width: 995px) {
    .footer-content {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 50px;
      padding-bottom: 35px;
    }
    .menu-items-footer-container {
      text-align: center;
      width: 100%;
      display: initial;
      padding-top: 30px;
    }
    .menu-items-footer {
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding-bottom: 20px;
      width: 100%;
      float: initial;
      text-align: center;
    }
    .menu-items-footer li {
      display: unset;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: normal;
      font-family: Lokem-Lighter;
      border-right: 1px solid #cba53c;
    }
    .social-media-container {
      width: 100%;
      float: initial;
      text-align: center;
    }
    .social-media-icons {
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding-bottom: 20px;
      padding-top: 20px;
    }
    .logo-content-footer-conatiner {
      display: inline-block;
      width: auto;
      padding-top: 10px;
      text-align: left;
    }
    .logo-content-footer {
      width: auto;
      float: initial;
      font-family: Lokem-Lighter;
      text-align: center;
    }

    .menu-items-footer li:first-child {
      padding-left: 0;
    }
    .logo-img-footer-container {
      float: initial;
      width: auto;
    }
    .logo-text-footer {
      display: block;
      width: auto;
      border-bottom: 1px solid #fff;
      margin-left: 0;
    }
    .address-footer {
      float: initial;
      width: 100%;
      text-align: center;
      font-family: Lokem-Lighter;
    }
    .contactFieldDiv {
      width: 100% !important;
      margin-right: 0% !important;
    }
  }
}
