@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.banner {
  width: 100%;
  height: 80vh;
  display: flex;
  background-image: url("../images/first-page.png");
  background-size: cover;
  background-position: center;

  .banner-content {
    text-align: center;
    color: white;
    max-width: 900px;

    .banner-title {
      font-family: "Lokem-Headings", sans-serif;
      font-size: 32px;
      margin-bottom: 6rem;
    }
  }
}
