@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; /* Name to be used in CSS */
  src: url("../../fonts/Sofia_Pro_Bold.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.training-dates-container {
  padding: 20px;
  font-family: "Lokem-Medium", sans-serif;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .training-table {
    width: 60%;
    border-collapse: collapse;
    font-size: 1rem;

    th,
    td {
      border: 1px solid #000;
      padding: 10px;
      text-align: left;
    }

    th {
      background-color: #fff;
      font-weight: bold;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .training-dates-container {
    margin-bottom: 1rem;
    padding: 10px;

    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
    }

    .training-table {
      width: 100%;
      font-size: 0.9rem;

      th,
      td {
        padding: 8px;
        font-size: 0.85rem;
      }

      th {
        text-align: left;
      }
    }
  }
}

@media (max-width: 480px) {
  .training-dates-container {
    padding: 5px;

    h1 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .training-table {
      font-size: 0.8rem;

      th,
      td {
        padding: 6px;
      }
    }
  }
}
