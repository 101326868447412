@font-face {
  font-family: "Pristina"; /* Name to be used in CSS */
  src: url("../fonts/Pristina-font.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.journey-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3% 5%;
  background-color: #e8d49c;
  background-image: url("../images/our3cBG.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .journey-content {
    max-width: 90%;
    margin: auto;
    padding-right: 5%;

    .journey-header {
      h1 {
        font-size: 2.5rem;
        color: #222;
        font-family: "Lokem-Lighter", sans-serif;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      hr {
        width: 50px;
        height: 2px;
        background-color: #d3b155;
        border: none;
        margin-bottom: 20px;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
        font-family: "Lokem-Medium", sans-serif;
        margin-bottom: 1rem;
      }

      h2 {
        font-size: 1.8rem;
        color: #bd8f14;
        font-family: "Lokem-Bold", sans-serif;
        margin-top: 2rem;
      }
    }

    .journey-map {
      margin-top: 20px;

      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .journey-info {
    width: 30%;
    margin-top: -15rem;
    margin-left: 55rem;
    max-width: 35%;
    padding-left: 5%;

    h2 {
      font-size: 1.8rem;
      color: #bd8f14;
      font-family: "Lokem-Bold", sans-serif;
      margin-bottom: 1rem;
    }

    ul {
      li {
        font-size: 1rem;
        line-height: 1.6;
        font-family: "Lokem-Medium", sans-serif;
        margin-bottom: 0.5rem;
        color: #222;
      }
    }
  }
}

/* Media Query for max-width: 768px */
@media (max-width: 768px) {
  .journey-section {
    padding: 20px;

    .journey-content,
    .journey-info {
      max-width: 100%;
      padding: 0;
      text-align: center;
    }
    .journey-info {
      width: 100%;
      margin-top: 1rem;
      margin-left: 5rem;
      max-width: 70%;
      text-align: left;
    }
    .journey-content {
      hr {
        margin-left: 9rem;
      }

      .journey-map {
        img {
          width: 100%;
        }
      }
    }
  }
}
