@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "SofiaProBold"; 
  src: url("../fonts/Sofia_Pro_Bold.ttf") format("truetype");
  font-style: normal; 
}
  
@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}
  
@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}
  
@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.IndividualTraining-main{
.top-page {
 background: url("../images/testimonial-banner.png") no-repeat center center,
            linear-gradient(to right, #ebd9a5, #e4cd8d);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.first-page-container {
text-align: center;
font-size: 50px;
margin-left: 10%;
margin-right: 10%;
padding-top: 70px;
padding-bottom: 70px;
color: #fff;
letter-spacing: 1px;
}
.first-section-sub {
font-size: 25px;
font-family: Lokem-Lighter;
}
.lokem-headers {
font-family: "Lokem-Lighter";
font-size: 60px;
}
.banner-heading {
color: #fff;
}
.first-page-heading {
padding-bottom: 45px;
}
.individual-train-your-main {
  width: 84% !important;
  padding-left: 8% !important;
  padding-right: 8% !important;
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
  /*background: url("../images/testimonial-banner.png") no-repeat center center,
            linear-gradient(to right, #ebd9a5, #e4cd8d);*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.individual-lokem-intro-main {
  background: #ece9e9dc !important;
}
.individual-tyt-heading-text {
  font-size: 25px;
  font-family: Lokem-Medium;
  margin-bottom: 10px;
  text-align: left;
}
.individual-tyt-heading {
  text-align: center !important;
  font-size: 35px !important;
  font-family: Lokem-Medium;
}
.individual-tyt-heading-line1 {
  width: 60px;
  border: 1px solid #000;
  background: #000;
  text-align: center;
}
.intro-video-main {
  width: 100%;
  text-align: center;
  padding-top: 35px;
}
.intro-video {
  border: 5px solid #cba53c;
  border-radius: 20px;
  height: auto;
  width: 1050px;
}


.courses-main {
  background-color: #fff !important;
}
.training-prog-txt {
  width: fit-content;
  margin: auto;
  position: relative;
}
.individual-row {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 25px;
}
.individual-tyt-text {
  font-family: Lokem-Lighter;
  letter-spacing: 0.2px !important;
  font-size: 20px;
}
.individual-column {
  position: relative;
  margin-right: 2%;
  transition: 0.5s;
  padding-left: 25px;
  padding-right: 20px;
  text-align: left;
  height: auto;
  padding-top: 1px;
  padding-bottom: 20px;
}

.individual-column:hover {
  /*transform: scale(1.05);*/
  background-color: #f0f0f0; /* Change background color */
}
.individual-tyt-sub-heading {
  font-size: 25px;
  color: #d3b155;
  font-family: Lokem-Medium;
  margin-bottom: 10px;
  text-align: left;
}
.individual-each-para-div {
  padding-bottom: 20px;
  text-align: left;
  line-height: 24px;
}
.more-padding {
  padding-bottom: 30px !important;
}
.individual-pathway-btn-container {
  bottom: 0px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.find-out-more-btn {
  background: #cba53c;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  font-family: Lokem-Medium;
  cursor: pointer;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
}
.individual-pathway-btn {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #cba53c;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  font-family: Lokem-Medium;
}
.individual-lokem-button {
  border: none !important;
  border-radius: 5px !important;
}

.embed-main {
  background: #ece9e9dc !important;
  color: #050505;
}
.tyt-heading-text {
  font-size: 25px;
  font-family: Lokem-Medium;
  margin-bottom: 10px;
  text-align: left;
}
.tyt-heading {
  text-align: center !important;
  font-size: 35px !important;
  font-family: Lokem-Medium;
}
.each-para-heading {
  padding-bottom: 15px;
  text-align: center;
}
.benfit-img {
  width: 120px;
  height: 120px;
  padding-bottom: 30px;
  /* object-fit: cover; */
}
.tyt-heading-line1 {
width: 60px;
border: 1px solid #000;
background: #000;
text-align: center;
}







/* General Styles */
.i-trainingHeading {
  text-align: center !important;
  font-size: 35px !important;
  font-family: Lokem-Medium;
  margin-bottom: 10px;
}

.i-trainingHeadingLine1 {
  width: 60px;
  border: 1px solid #000;
  background: #000;
  text-align: center;
}

.row-benfits {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 25px;
}
.benefits-row {
  height: 420px;
}
.i-training-text {
  font-family: Lokem-Lighter;
  letter-spacing: 0.2px;
  font-size: 20px;
}



.arrow {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.faicon {
  color: #787775;
}

.circle-icon {
  background: #cba53c;
  width: 35px;
  border-radius: 50%;
  height: 35px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.column1 {
  position: relative;
  margin-right: 2%;
  transition: 0.5s;
  padding: 1px 20px 20px 25px;
  width: 30%;
  text-align: left;
  height: auto;
  width:auto;
}
/*.section-1-width {
  width: 345.48px;
}*/
.embed-main {
  background: #ece9e9dc !important;
  color: #050505;
}
.column1:hover {
  background-color: #e0e1e3;
  cursor: pointer;
}
.column-text {
  height: 331.2px;
}

.image-benfit {
  text-align: center;
  padding-top: 10px;
  
}

.benfit-img {
  width: 120px;
  height: 120px;
  padding-bottom: 30px;
  /* object-fit: cover; */
}

.each-para-heading {
  padding-bottom: 15px;
  text-align: center;
}

.individual-heading {
  letter-spacing: 1px;
  font-family: Lokem-Medium;
  color: #d3b155;
  font-size: 26px;
  min-height: 60px;
}

.individual-para {
  padding-bottom: 20px;
  text-align: left;
  line-height:24px;
}



@media (max-width: 1200px) {
  .arrow {
    display: none;
}
.column {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: center;
  height: auto;
  padding-top: 1px;
  padding-bottom: 20px;
  margin: auto;
  margin-bottom: 0;
}
.column1 {
padding-left: 0;
padding-right: 0;
width: 100%;
text-align: center;
height: auto;
padding-top: 1px;
padding-bottom: 20px;
margin: auto;
margin-bottom: 0;
}
.image-benfit {
text-align: center;
padding-top: 10px;
}
.benfit-img {
padding-bottom: 0;
}
.embed-heading {
display: inline-block;
padding-bottom: 10px;
letter-spacing: 1px;
font-family: Lokem-Lighter;
min-height: initial;
padding-top: 20px;
text-align: center;
}
.hide {
display: initial;
}

  .top-page {
      background:url("../images/testimonial-banner.png") top;
      background-size: cover;
  }
  .first-page-container {
    text-align: center;
    font-size: 20px;
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 60px;
    color: #fff;
}
.lokem-headers {
  font-family: "Lokem-Lighter";
  font-size: 40px;
}
.first-section-sub {
font-size: 22px;
font-family: Lokem-Lighter;
}
.train-your-main {
width: 84% !important;
padding-left: 8% !important;
padding-right: 8% !important;
padding-bottom: 0px;
padding-top: 20px;
text-align: center;

}
.tyt-heading {
text-align: center !important;
font-size: 30px !important;
font-family: Lokem-Medium;
}
.tyt-heading-text {
margin-top: 0px;
}
.courses-heading-line-main {
margin-bottom: 10px;
}
.tyt-heading-line1 {
width: 60px;
border: 1px solid #000;
background: #000;
text-align: center;
}
.intro-video-main {
padding-bottom: 20px;
padding-top: 20px;
}
.intro-video {
border: 5px solid #cba53c;
border-radius: 20px;
height: auto;
width: 100%;
}
.courses-main {
background-color: #fff !important;
}
train-your-main1 {
width: 84% !important;
padding-left: 8% !important;
padding-right: 8% !important;
padding-bottom: 0px;
padding-top: 20px;
text-align: center;
}
.individual-row {
display: inline-block;
text-align: center;
justify-content: center;
}
.individual-tyt-heading-line1
{
width: 60px;
border: 1px solid #000;
background: #000;
text-align: center;
}.individual-column {
padding-left: 0;
padding-right: 0;
width: 100%;
text-align: center;
height: auto;
padding-top: 1px;
padding-bottom: 20px;
margin: auto;
margin-bottom: 0;
}
.individual-tyt-sub-heading {
text-align: center;
margin-top: 0px;
font-size: 25px;
color: #d3b155;
font-family: Lokem-Medium;
margin-bottom: 10px;
}
.individual-each-para-div {
padding-bottom: 35px !important;
text-align: center;
line-height: 24px;
}
.more-padding {
padding-bottom: 10px ;
}
.individual-pathway-btn-container {
position: initial;
}
.training-prog-txt {
width: fit-content;
margin: auto;
position: relative;
}
.column-text {
height: auto; /* Adjust height dynamically for mobile */
padding: 16px; /* Add padding for better spacing */
}


.i-trainingHeading {
  text-align: center !important;
  font-size: 30px !important;
  font-family: Lokem-Medium;
}

.i-trainingHeadingLine1 {
  width: 60px;
  border: 1px solid #000;
  background: #000;
  text-align: center;
}
  &:hover {
    background-color: #e0e1e3;
  }


.benfit-img {
  padding-bottom: 0;
}

.individual-heading {
  display: inline-block;
      padding-bottom: 10px;
      letter-spacing: 1px;
      font-family: Lokem-Lighter;
      min-height: initial;
      padding-top: 20px;
      text-align: center;
}

.individual-para {
  padding-bottom: 35px;
  text-align: center;
  line-height: 24px;
}

.benefits-row {
    display: inline-block;
    text-align: center;
    justify-content: center;

}
  .individual-train-your-main {
      width: 84% !important;
      padding-left: 8% !important;
      padding-right: 8% !important;
      padding-bottom: 0px;
      padding-top: 20px;
      text-align: center;
     /* background: url("../images/testimonial-banner.png") no-repeat center center,
            linear-gradient(to right, #ebd9a5, #e4cd8d);*/
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
  }
  .individual-tyt-heading {
      text-align: center !important;
      font-size: 30px !important;
      font-family: Lokem-Medium;
  }

  .intro-video {
      border: 5px solid #cba53c;
      border-radius: 20px;
      height: auto;
      width: 100%;
  }
  .intro-video-main {
      padding-bottom: 20px;
      padding-top: 20px;
  }
}

}