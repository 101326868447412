@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.agileCulture-main {
  .train-your-main-agile {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
  }

  .tyt-paras-agile {
    width: 100%;
    display: inline-flex;
  }

  .secs-tyt-cols-agile {
    width: 100%;
    text-align: left;
  }

  .tyt-text-agile {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
  }

  .each-para-div-agile {
    padding-bottom: 25px;
  }

  .new-para-agile {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .para-one-agile {
    font-family: "Lokem-medium";
  }

  .new-para-agile {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .flex-di-agile {
    flex-direction: row-reverse;
  }

  .tyt-sub-heading-agile {
    font-size: 28px;
    color: #000;
    font-family: "Lokem-Medium";
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .each-para-div-agile {
    padding-bottom: 25px;
  }

  .remove-bottom-agile {
    padding-bottom: 0 !important;
  }

  .tyt-text-blog-agile {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }

  .row-agile {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .column-agile {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-agile {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .post-name-agile {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-agile {
    padding-top: 10px;
  }

  .rate-article-text-agile {
    font-size: 17px;
  }

  .rating-agile {
    font-size: 35px;
  }

  .rating-details-text-agile {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-agile {
    margin-top: 25px;
  }

  .social-text-agile {
    font-size: 20px;
  }

  .social-icons-main-agile {
    margin-top: 14px;
  }

  .blog-social-media-icons-agile {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .blog-social-media-icons-agile i {
    width: 20px;
  }

  .fa-agile {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .prev-next-post-agile {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .side-header-agile {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }

  .comment-text-head-agile {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }

  .comment-icon-agile {
    font-size: 40px;
    margin-left: 15px;
  }

  .hrLine-agile {
    width: 100%;
  }

  @media (max-width: 995px) {
    .tyt-paras-agile {
      width: 100%;
      display: inline-block;
    }

    .tyt-text-agile {
      text-align: center;
      font-size: 17px;
    }

    .tyt-sub-heading-agile {
      font-size: 25px;
      color: #000;
      font-family: "Lokem-Medium";
    }

    .row-agile {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }

    .bullets-agile li {
      text-align: left;
    }
  }
}
