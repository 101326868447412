

.book-cal-main{
    /* Main container styles */
  
  
  .tyt-text-heading {
    text-align: left;
}
.tyt-heading-text {
    font-size: 45px !important;
    font-family: Lokem-Lighter !important;
    margin-bottom: 0px !important;
}
.tyt-heading-line-main {
    margin-bottom: 30px;
}
  
  .tyt-heading-line {
    border: none;
    height: 2px;
    background-color: #000;
  }
  
  .error {
    color: red;
    font-size: 14px;
    font-family:Lokem-Medium;
  }
  #business-form input[type="text"],
	select,
	textarea {
		width: 100%;
		height: 32px;
		padding: 0;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-sizing: border-box;
		margin-top: 6px;
		padding-left: 6px;
		border-radius: 3px;
		vertical-align: middle;
	}

	#business-form div {
		margin-bottom: 16px;
	}

    .tyt-heading-line {
		width: 60px;
		border: 1.9px solid #d3b155;
		background: #d3b155;
		float: left;
		margin-left: 3px;
	}
    .book-call-container {
		border-radius: 5px;
		/* background-color: #f2f2f2; */
		/* padding: 20px; */
		text-align: left;
	}
    .contactMsg {
		text-align: center;
		color: #d5a215;
	}
    .btn-main {
        text-align: center;
    }
    @media (max-width: 995px) {

        .train-your-main-corporate {
			display: inline-block;
			width: 90% !important;
			padding-left: 5% !important;
			padding-right: 5% !important;
			padding-bottom: 20px;
			padding-top: 20px;
			text-align: center;
			background-image: url("../images/our3cBG.png"),
				linear-gradient(to right, #ebd9a5, #e4cd8d);
			background-repeat: no-repeat;
			background-position: center;
		}

        .tyt-text-heading {
			text-align: center !important;
		}
        .tyt-heading-text {
			font-size: 30px !important;
			font-family: Lokem-Lighter;
		}
        .tyt-heading-line {
			width: 60px;
			border: 1.9px solid #d3b155;
			background: #d3b155;
			float: initial;
			margin-left: auto;
		}
    }
}
  