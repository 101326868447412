@font-face {
  font-family: "Pristina"; /* Name to be used in CSS */
  src: url("../fonts/Pristina-font.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

.payment-details {
  max-width: 400px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border: 1px solid #e6e6e6;
  margin-top: 1rem;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.5rem;
    font-family: "Lokem-Headings", sans-serif;
    text-align: left;
    opacity: 0.6;
  }

  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    button {
      width: 50%;
      height: 60px;
      padding: 10px 15px;
      font-family: "Lokem-Medium", sans-serif;
      opacity: 0.6;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      background-color: white;
      transition: background-color 0.3s, color 0.3s;
      text-align: left;

      &.active-tab {
        border: 1px solid #0570de;
        color: #0570de;
        background-color: #f0f7ff;
      }
    }

    button + button {
      margin-left: 10px;
    }
  }

  .form-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.4s ease, transform 0.4s ease;
    display: none;

    &.show {
      opacity: 1;
      transform: translateY(0);
      display: block;
    }
  }

  .input-group {
    margin-bottom: 0.8rem;

    label {
      font-size: 0.8rem;
      display: block;
      margin-bottom: 0.3rem;
    }

    input,
    select {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      outline: none;
      font-size: 0.9rem;
      border: 1px solid #e6e6e6;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
      transition: border-color 0.2s;

      &:focus {
        border-color: #4b9ce2;
      }

      &.error {
        border-color: red;
      }
    }

    input {
      width: 90%;
      height: 18px;

      ::placeholder {
        color: #737373;
        opacity: 0.6;
        font-size: 0.8rem;
      }
    }

    select {
      width: 100%;
      height: 40px;

      .option {
        color: #737373;
        opacity: 0.6;
        font-size: 0.8rem;
      }
    }

    .error-message {
      color: red;
      font-size: 0.8rem;
      margin-top: 0.2rem;
    }
  }

  .input-row {
    display: flex;
    justify-content: space-between;

    .input-group {
      width: 48%;
    }
  }

  .payment-btn {
    width: 40%;
    font-family: "Lokem-Lighter", sans-serif;
    font-size: 0.8rem;
    padding: 7px;
    border: none;
    border-radius: 4px;
    background-color: #c3a555;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
  }

  .google-pay-info {
    text-align: left;
    padding: 1rem;
    border: 1px solid #ddd;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
    border-radius: 4px;
    color: #333;

    p {
      margin: 0.5rem 0;
      font-family: "Lokem-Lighter", sans-serif;
    }

    .google-play-txt {
      border-bottom: 1px solid #ddd;
      padding-bottom: 1rem;
      font-size: 0.8rem;
    }

    .google-play-txt2 {
      font-size: 0.7rem;
      line-height: 1rem;
      font-family: "Lokem-Medium", sans-serif;
      opacity: 0.6;
    }
  }

  .termsTxt {
    margin-top: 1rem;
    font-family: "Lokem-Lighter", sans-serif;
    font-size: 0.8rem;

    a {
      text-decoration: none;
      color: #deab20;
    }
  }
}
