@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: Lokem-Headings !important;
  overflow-x: hidden !important;
  line-height: initial !important;
  color: #000 !important;
  background-color: #fff !important;
}

.testimonial-main {
  .train-your-main-testi {
    width: 84% !important;
    padding-left: 8% !important;
    padding-right: 8% !important;
    padding-bottom: 60px;
    padding-top: 60px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
  }

  .tyt-paras {
    width: 100%;
    display: inline-flex;
    padding-bottom: 10px;
  }

  .flex-dir {
    flex-direction: row-reverse;
  }

  .secs-tyt-col1 {
    width: 30%;
  }

  .our-3cs-image1 {
    border-radius: 50%;
    width: 220px;
    height: 220px;
    border: none;
    margin-top: 0px;
    object-fit: cover;
    margin-top: 20px;
  }

  .secs-tyt-col2 {
    width: 70%;
    padding-left: 62px;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .tyt-text {
    font-family: Lokem-Medium;
    letter-spacing: 0.2px;
    font-size: 20px;
  }

  .each-para-div-testi {
    display: inline-flex;
    letter-spacing: initial;
  }

  .tyt-sub-heading-testi {
    font-size: 30px;
    color: #000;
    font-family: "Lokem-Lighter";
  }

  .quote-top-testi {
    height: 40px;
    width: 45px;
    padding-right: 11px;
    margin-left: -64px;
    margin-right: 6px;
  }

  .secs-font {
    font-size: 21px;
    letter-spacing: 1px;
    font-family: "Lokem-lighter";
  }

  .offer-para1 {
    margin-top: 22px;
  }

  .read-more-less {
    color: #d3b155;
    font-size: 17px;
    font-family: Lokem-Medium;
    cursor: pointer;
  }

  .read-more-less:hover {
    color: #d3b155;
    font-size: 17px;
    font-family: Lokem-Medium;
    cursor: pointer;
  }

  @media only screen and (max-width: 995px) {
    .train-your-main-testi {
      width: 84% !important;
      padding-left: 8% !important;
      padding-right: 8% !important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
    }
    .tyt-paras {
      width: 100%;
      display: inline-block;
    }
    .secs-tyt-col1 {
      width: 100%;
      text-align: center;
    }
    .our-3cs-image1 {
      border-radius: 50%;
      width: 250px;
      height: 250px;
      border: none;
      margin-top: 0px;
      object-fit: cover;
      margin-top: 0px;
    }
    .secs-tyt-col2 {
      width: 100%;
      padding-left: 0px;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      display: flex;
    }
    .tyt-sub-heading {
      font-size: 24px;
      padding-bottom: 10px !important;
    }
    .quote-top-testi {
      height: 25px;
      width: 25px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .secs-font {
      font-size: 20px;
      font-family: "Lokem-lighter";
      text-align: center;
    }
    .offer-para1 {
      margin-top: 0px;
    }
  }
}
