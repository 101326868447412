@font-face {
	font-family: "Lokem-Headings";
	src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}

.corporate-main {
	.top-page {
		background: url("../images/testimonial-banner.png") no-repeat center center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.first-page-container {
		text-align: center;
		font-size: 50px;
		margin-left: 10%;
		margin-right: 10%;
		padding-top: 70px;
		padding-bottom: 70px;
		color: #fff;
		letter-spacing: 1px;
	}

	.lokem-headers {
		font-family: "Lokem-Lighter";
		font-size: 60px;
	}

	.first-page-heading {
		padding-bottom: 45px;
	}

	.banner-heading {
		color: #fff;
	}

	.first-section-sub {
		font-size: 25px;
		font-family: Lokem-Lighter;
	}

	.training-dates-button-section {
		padding-top: 30px;
		display: inline-flex;
	}

	.mar-right {
		margin-right: 15px;
	}

	.lokem-button {
		border: none !important;
		border-radius: 5px !important;
	}

	.get-in-touch-corporate {
		padding: 10px;
		padding-left: 30px;
		padding-right: 30px;
		background: #c9a328;
		color: #fff;
		font-size: 22px;
		border: none;
		border-radius: 5px;
		font-family: Lokem-Lighter;
		text-decoration: none;
	}

	.view-program-top {
		padding: 10px;
		padding-left: 30px;
		padding-right: 30px;
		background: #706137;
		color: #fff;
		font-size: 22px;
		border: 1px solid white;
		border-radius: 5px;
		font-family: Lokem-Lighter;
		text-decoration: none;
		cursor: pointer;
	}

	/* Section 2 */

	.images-sec-main {
		width: 70% !important;
		padding-left: 15% !important;
		padding-right: 15% !important;
	}

	.train-your-main-corporate {
		width: 84%;
		padding-left: 8%;
		padding-right: 8%;
		padding-bottom: 60px;
		padding-top: 60px;
		text-align: center;
		background-image: url("../images/our3cBG.png"),
			linear-gradient(to right, #ebd9a5, #e4cd8d);
		background-repeat: no-repeat;
		background-position: center;
	}

	.tyt-heading-main {
		width: 100%;
		padding-bottom: 45px;
	}

	.tyt-heading-corporate {
		text-align: center;
		font-size: 35px;
		font-family: Lokem-Medium;
	}

	.tyt-paras {
		width: 100%;
		display: inline-flex;
		padding-bottom: 50px;
	}

	.secs-tyt-col1 {
		width: 50%;
		text-align: right;
	}

	.tyt-text {
		font-family: Lokem-Lighter;
		letter-spacing: 0.2px;
		font-size: 20px;
	}

	.each-para-div-corporate {
		display: inline-flex;
		padding-bottom: 20px;
		letter-spacing: initial;
	}

	.offer-para1 {
		margin-top: 0px;
	}

	.sec1-image {
		width: 100%;
		height: 400px;
		border: none;
		object-fit: cover;
		border-radius: 10px;
	}

	.secs-tyt-col2 {
		width: 50%;
		padding-left: 30px;
		text-align: left;
		flex-direction: column;
		justify-content: center;
		display: flex;
	}

	.tyt-sub-heading-corporate {
		font-size: 35px;
		color: #000;
		font-family: "Lokem-Medium";
		padding-bottom: 15px !important;
	}

	.secs-font {
		font-size: 21px;
		letter-spacing: 1px;
		font-family: "Lokem-Lighter";
	}

	.flex-dir {
		flex-direction: row-reverse;
	}

	.embed-main-corporate {
		background: #fff;
		color: #000;
	}

	.row-corporate {
		display: flex;
		text-align: center;
		justify-content: center;
	}

	.tyt-text {
		font-family: Lokem-Lighter;
		letter-spacing: 0.2px;
		font-size: 20px;
	}

	.first-col {
		margin-left: 80px;
	}

	.column-corporate {
		margin-right: 100px;
		flex-direction: column;
		flex: 1 1 0%;
	}

	.embed-heading {
		display: inline-flex;
		padding-bottom: 10px;
		letter-spacing: 1px;
		font-family: Lokem-Medium;
		min-height: 60px;
	}

	.each-para-div {
		display: inline-flex;
		padding-bottom: 20px;
		letter-spacing: initial;
	}

	.last-col {
		margin-right: 80px !important;
	}

	.view-program-main {
		padding-top: 25px;
	}

	.lokem-button {
		border: none !important;
		border-radius: 5px !important;
	}

	.view-program-bottom {
		padding: 10px;
		padding-left: 30px;
		padding-right: 30px;
		background: #c9a328;
		color: #fff;
		font-size: 22px;
		border: none;
		border-radius: 5px;
		font-family: Lokem-Lighter;
		text-decoration: none;
		cursor: pointer;
	}

	.padding-bot {
		padding-bottom: 30px;
	}

	.max-width-div {
		max-width: initial;
		width: 100%;
		display: inline-block;
	}

	.tyt-heading-text {
		font-size: 45px !important;
		font-family: Lokem-Lighter !important;
		margin-bottom: 0px !important;
	}

	.tyt-heading-line-main {
		margin-bottom: 30px;
	}

	.tyt-heading-line {
		width: 60px;
		border: 1.9px solid #d3b155;
		background: #d3b155;
		float: left;
		margin-left: 3px;
	}

	.why-lokem-main-text {
		padding-top: 0px;
		text-align: left;
		font-size: 22px !important;
		font-family: Lokem-Medium !important;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.discovery-row1 {
		display: inline-flex;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.discovery-row1-col1 {
		width: 75%;
		float: left;
		font-family: Lokem-Lighter;
		font-size: 18px;
		text-align: left;
		padding-top: 30px;
	}

	.tyt-text-heading {
		text-align: left;
	}

	.tyt-text {
		font-family: Lokem-Lighter;
		letter-spacing: 0.2px;
		font-size: 20px;
	}

	.discovery-row1-col2 {
		float: left;
		padding-left: 20px;
	}

	.discovery-image {
		border-radius: 50%;
		width: 300px;
		height: 300px;
		border: 1px solid black;
		border: none;
		object-fit: cover;
	}

	.book-call-container {
		border-radius: 5px;
		/* background-color: #f2f2f2; */
		/* padding: 20px; */
		text-align: left;
	}

	.btn-main {
		text-align: center;
	}

	.contactMsg {
		text-align: center;
		color: #d5a215;
	}

	#business-form input[type="text"],
	select,
	textarea {
		width: 100%;
		height: 32px;
		padding: 0;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-sizing: border-box;
		margin-top: 6px;
		padding-left: 6px;
		border-radius: 3px;
		vertical-align: middle;
	}

	#business-form div {
		margin-bottom: 16px;
	}

	.book-call-btn {
		background-color: #d3b155;
		color: white;
		padding: 12px 20px;
		border-radius: 4px;
		cursor: pointer;
		font-family: Lokem-Medium;
		font-size: 18px;
		border: none !important;
		border-radius: 5px !important;
	}

	.error {
		color: #df1b41;
		font-weight: bold;
		font-size: 15px;
		font-family: Lokem-Headings;
		text-align: left;
		margin-top: 5px;
		margin-left: 2px;
	}

	label {
		font-family: Lokem-Medium;
	}

	@media (max-width: 995px) {
		.first-page-container {
			text-align: center;
			font-size: 20px;
			margin-left: 3%;
			margin-right: 3%;
			padding-top: 60px;
			color: #fff;
		}

		.lokem-headers {
			font-family: "Lokem-Lighter";
			font-size: 40px !important;
			padding-bottom: 16px !important;
		}

		.first-section-sub {
			font-size: 22px;
			font-family: Lokem-Lighter;
		}

		.training-dates-button-section {
			padding-top: 30px;
			padding-bottom: 60px;
			display: inline-block;
		}

		.mar-right {
			margin-right: 0;
			margin-bottom: 30px;
		}

		.get-in-touch-corporate {
			padding: 8px;
			padding-left: 18px;
			padding-right: 18px;
			font-size: 20px;
			border: none;
			border-radius: 5px;
			font-family: Lokem-Lighter;
		}

		.view-program-top {
			padding: 8px;
			padding-left: 18px;
			padding-right: 18px;
			font-size: 20px;
			border-radius: 5px;
			border: 1px solid white;
			font-family: Lokem-Lighter;
		}

		.images-sec-main {
			width: 90% !important;
			padding-left: 5% !important;
			padding-right: 5% !important;
		}

		.train-your-main-corporate {
			display: inline-block;
			width: 90% !important;
			padding-left: 5% !important;
			padding-right: 5% !important;
			padding-bottom: 20px;
			padding-top: 20px;
			text-align: center;
			background-image: url("../images/our3cBG.png"),
				linear-gradient(to right, #ebd9a5, #e4cd8d);
			background-repeat: no-repeat;
			background-position: center;
		}

		.tyt-heading-main {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}

		.tyt-heading-corporate {
			text-align: center;
			font-size: 22px !important;
			font-family: Lokem-Medium;
		}

		.tyt-paras {
			width: 100%;
			display: inline-block;
		}

		.sec1-image {
			width: 100%;
			height: AUTO;
			border: none;
			object-fit: cover;
			border-radius: 10px;
		}

		.secs-tyt-col1 {
			width: 100%;
			text-align: center;
		}

		.secs-tyt-col2 {
			width: 100%;
			padding-left: 0px;
			text-align: center;
			flex-direction: column;
			justify-content: center;
			display: flex;
		}

		.secs-font {
			font-size: 21px;
		}

		.tyt-sub-heading-corporate {
			font-size: 24px;
			padding-bottom: 10px !important;
		}

		.padding-bot {
			padding-bottom: 15px;
		}

		.sec-headings {
			text-align: center;
			font-size: 22px;
			font-family: Lokem-Medium;
		}

		.row-corporate {
			display: inline-block;
			text-align: center;
			justify-content: center;
		}

		.column-corporate {
			margin-right: 0;
			flex-direction: initial;
			flex: initial;
		}

		.first-col {
			margin-left: 0;
		}

		.last-col {
			margin-right: 0 !important;
		}

		.embed-heading {
			display: inline-flex;
			padding-bottom: 10px;
			letter-spacing: 1px;
			font-family: Lokem-Medium;
			min-height: initial;
			padding-top: 20px;
		}

		.view-program-main {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.view-program-bottom {
			padding: 8px;
			padding-left: 18px;
			padding-right: 18px;
			font-size: 20px;
			border-radius: 5px;
			border: 1px solid white;
			font-family: Lokem-Lighter;
		}

		.max-width-div {
			max-width: initial;
			width: 100%;
			display: inline-block;
		}

		.tyt-text-heading {
			text-align: center !important;
		}

		.tyt-heading-text {
			font-size: 30px !important;
			font-family: Lokem-Lighter;
		}

		.tyt-heading-line {
			width: 60px;
			border: 1.9px solid #d3b155;
			background: #d3b155;
			float: initial;
			margin-left: auto;
		}

		.why-lokem-main-text {
			padding-top: 0px;
			text-align: center;
			font-size: 20px !important;
			font-family: Lokem-Medium;
			margin-top: 20px !important;
			margin-bottom: 20px !important;
		}

		.discovery-row1 {
			display: inline-block;
			width: 98%;
			margin-left: 1%;
			margin-right: 1%;
			margin-bottom: 20px;
			margin-top: 0;
			text-align: center;
		}

		.discovery-row1-col1 {
			width: 100%;
			float: initial;
			font-family: Lokem-Lighter;
			font-size: 18px;
			text-align: center;
			padding-top: 0;
		}

		.discovery-row1-col2 {
			float: initial;
			width: 100%;
			padding-left: 0;
		}
	}
}