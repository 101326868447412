@font-face {
  font-family: "Lokem-Headings";
  src: url("../../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.implement-main {
  .train-your-main-imp {
    width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;
  }
  .blog-welcome-text-imp {
    width: 100%;
    text-align: left;
    font-family: Lokem-Medium;
    font-size: 20px;
  }
  .top-space-imp {
    padding-top: 18px;
  }

  .blog-welcome-text-imp {
    width: 100%;
    text-align: left;
    font-family: Lokem-Medium;
    font-size: 20px;
  }
  .tyt-paras-imp {
    width: 100%;
    display: inline-flex;
  }
  .secs-tyt-cols-imp {
    width: 100%;
    display: flex;
  }
  .tyt-text-imp {
    font-family: "Lokem-Lighter";
    font-size: 20px;
    width: 60%;
  }
  .tyt-sub-heading-imp {
    text-align: left;
    font-size: 30px;
    font-family: "Lokem-Medium";
    margin-bottom: 10px;
  }
  .each-para-div-imp {
    font-family: Lokem-Lighter;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
  }
  .image-blog-imp {
    width: 36%;
    margin-top: 55px;
    object-fit: cover;
    margin-left: 30px;
    margin-right: 30px;
  }
  .other-blogs-new-imp {
    width: 100%;
  }
  .padding-top-para-imp {
    padding-top: 20px;
  }

  .each-para-blog-imp {
    width: 100%;
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;
    font-family: Lokem-Lighter;
  }
  .image-blog2-imp {
    width: 36%;
    object-fit: cover;
    margin-right: 30px;
  }
  .row-imp {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 25px;
  }
  .tyt-text-blog-imp {
    font-family: "Lokem-Lighter";
    letter-spacing: 0.2px;
    font-size: 20px;
    margin-top: 50px !important;
  }
  .column-imp {
    position: relative;
    margin-right: 2%;
    transition: 0.5s;
    padding-left: 25px;
    padding-right: 20px;
    width: 30%;
    height: auto;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .other-blogs-img-imp {
    width: 300px;
    margin-top: 0px;
    margin-bottom: 6px;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
  }

  .post-name-imp {
    font-size: 17px;
    margin-top: 5px;
  }

  .padding-top-imp {
    padding-top: 10px;
  }

  .rate-article-text-imp {
    font-size: 17px;
  }

  .rating-imp {
    font-size: 35px;
  }

  .rating-details-text-imp {
    padding-top: 3px;
    font-family: "Lokem-Medium";
    font-size: 14px;
  }

  .share-your-network-imp {
    margin-top: 25px;
  }

  .social-text-imp {
    font-size: 20px;
  }

  .social-icons-main-imp {
    margin-top: 14px;
  }

  .blog-social-media-icons-imp {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-top: 0;
    display: inline-flex !important;
    font-size: 20px;
    color: #39798f;
  }

  .blog-social-media-icons-imp i {
    width: 20px;
  }

  .fa-imp {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .prev-next-post-imp {
    font-size: 16px;
    font-family: Lokem-Medium;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .side-header-imp {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-family: Lokem-header;
    font-size: 50px;
  }

  .comment-text-head-imp {
    font-size: 30px;
    font-family: "Lokem-Lighter" !important;
  }

  .comment-icon-imp {
    font-size: 40px;
    margin-left: 15px;
  }

  .hrLine-imp {
    width: 100%;
  }
  @media (max-width: 995px) {
    .train-your-main-imp {
      width: 90% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 10px;
      padding-top: 20px;
    }
    .blog-welcome-text-imp {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
    .secs-tyt-cols-imp {
      width: 100%;
      display: block;
    }
    .tyt-text-imp {
      font-family: "Lokem-Lighter";
      font-size: 20px;
      width: 100%;
    }

    .tyt-sub-heading-imp {
      text-align: center;
      font-size: 25px;
      font-family: "Lokem-Medium";
      margin-bottom: 10px;
    }
    .each-para-div-imp {
      font-size: 17px;
      text-align: center;
      width: 100%;
    }
    .image-blog-imp {
      width: 100%;
      margin-top: 10px;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
    }
    .other-blogs-new-imp {
      width: 100%;
    }
    .each-para-blog-imp {
      width: 100%;
      font-size: 17px;
      text-align: center;
      font-family: "Lokem-Lighter";
      margin-bottom: 15px;
    }
    .image-blog2-imp {
      width: 100%;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
    }
    .row {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }
    .row-imp {
      display: block;
      text-align: center;
      justify-content: center;
      margin-top: 25px;
    }

    .column {
      position: relative;
      margin-right: 2%;
      transition: 0.5s;
      padding-left: 25px;
      padding-right: 20px;
      width: auto;
      height: auto;
      padding-top: 1px;
      padding-bottom: 20px;
    }
  }
}
