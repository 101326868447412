

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.productOwner-main{
// Banner Section
// Banner Section
.top-page-po {
  background: url("../images/courses-main1.png") ;
  min-height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first-page-container-po {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 0px;
  color: #fff;
}
.lokem-headers-po {
  font-family: "Lokem-Lighter";
  font-size: 60px;
}

.first-page-heading-po {
  padding-bottom: 0px;
}
.banner-heading-po {
  color: #fff;
}
.training-dates-button-section-po {
  padding-bottom: 10px;
}
.book-btn-position-po {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: -42px;
}
.book-place-button-po {
  padding: 18px;
  padding-left: 30px;
  padding-right: 30px;
  background: #cba53c;
  color: #fff;
  font-size: 25px;
  border: none;
  border-radius: 5px;
  font-family: Lokem-Lighter;
  cursor: pointer;
}
.lokem-button-po {
  border: none !important;
  border-radius: 5px !important;
}

// Section 2
// Section 2
.training-overview-main-po {
  background: #fff;
}
.train-your-main-po {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
  /*background-image: url(../media/images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d);*/
  background-repeat: no-repeat;
  background-position: center;
}
.max-width-div-po {
  /* max-width: 1200px; */
  width: 100%;
  display: inline-block;
}
.overview-cols-main-po {
  width: 100%;
  display: inline-flex;
}
.overview-col1-po {
  width: 65%;
    text-align: left;
    padding-top: 25px;
}
.tyt-heading-main-po {
  width: 100%;
  padding-bottom: 25px;
}
.tyt-heading-po {
  text-align: left;
}
.tyt-heading-text-po {
  font-size: 45px;
  font-family: Lokem-Medium;
}
.tyt-text-po {
  font-family: Lokem-Medium;
  letter-spacing: 0.2px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 5px;
}
.each-para-div-po {
  display: inline-flex;
  padding-bottom: 25px;
}
.overview-col2-po {
  float: right;
  padding-left: 30px;
  width: 30%;
}
.course-overview-image1-po {
  float: right;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
}
.course-overview-image2-po {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  float: left;
  margin-top: 20px;
  object-fit: cover;
}


// Section 3
// Section 3
.tobj-main-po {
  width: 80%;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fcdf8f;
}


.tobj-heading-po {
  text-align: center !important;
  color: black !important;
  font-family: Lokem-Medium;
}



.tobj-sub-po {
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 21px;
  font-family: Lokem-Medium;
}

.tobj-col-main-po {
  width: 100%;
}



.tobj-row-po {
  display: inline-flex;
  width: 100%;
  padding-bottom: 40px;
}

.tobj-col1-po {
  width: 44%;
  margin-left: 3%;
  margin-right: 3%;
}

.tobj-color-po {
  margin-bottom: 5px;
}

.tyt-sub-heading-po {
  font-size: 25px;
  color: #fff;
  font-family: "Lokem-Lighter";
}

.tobj-img-po {
  width: 80px;
  height: 90px;
}

/* Section 4 */

.training-overview-main-po {
  background: #fff;
}
.train-your-main-po {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
  /*background-image: url(../media/images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d);*/
  background-repeat: no-repeat;
  background-position: center;
}

.padding-bottom-para-po {
  padding-bottom: 40px !important;
}

.overview-col2-po {
  float: right;
  padding-left: 30px;
}

.training-dates-main-po {
  background: url(../images/our3cBG.png), linear-gradient(to right, #ebd9a5, #e4cd8d);
  background-position: right;
  width: 260px;
  text-align: center;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 35px;
  margin-top: 45px;
}

.training-dates-text-po {
  color: #000;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: Lokem-Medium;
  font-size: 30px;
}

.location-online-po {
  padding-bottom: 20px;
}

.package-includes-po {
  padding-bottom: 50px;
}

.booknow-style-po {
  padding-bottom: 45px;
}

.training-dates-button-section-po {
  padding-bottom: 10px;
}

.lf-use-code-main-po {
  font-family: Lokem-Lighter;
  font-size: 16px;
  padding-bottom: 5px;
}

.lf-coupon-code-po {
  color: #161411;
  font-family: "Lokem-Headings";
}

.lokem-button-po {
  border: none !important;
  border-radius: 5px !important;
}

.book-place-button-po {
  padding: 18px;
  padding-left: 30px;
  padding-right: 30px;
  background: #cba53c;
  color: #fff;
  font-size: 25px;
  border: none;
  border-radius: 5px;
  font-family: Lokem-Lighter;
}

.float-initial-po {
  float: initial !important;
}

.course-overview-image2-po {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  float: left;
  margin-top: 20px;
  object-fit: cover;
}

.fac-course-po {
  color: #e9b004;
  padding-top: 60px;
  padding-bottom: 10px;
  font-family: Lokem-Medium;
}

.modules-covered-po div {
  border-bottom: 1px solid #e8cd77;
  padding-top: 10px;
}

.mc-heading-po {
  padding-bottom: 5px;
  border: none !important;
  color: #e9b004;
  font-family: Lokem-Medium;
}

.booknow-style-po {
  padding-bottom: 45px;
}

// Section 5
.po-journey-main {
  display: flex;
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background: #fff;
  position: relative;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.po-Infograph {
width: 100%;
}

@media (max-width: 995px) {
  .book-btn-position-po{
    bottom: -30px;
  }

  .training-overview-main-po {
    background: #fff;
  }
  .train-your-main-po {
    display: inline-block;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
  }
  .max-width-div-po {
    max-width: initial;
    width: 100%;
    display: inline-block;
  }
  .overview-cols-main-po {
    width: 100%;
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }
  .overview-col1-po {
    width: 100%;
    text-align: center;
  }
  .tyt-heading-main-po {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .tyt-heading-text-po {
    font-size: 30px;
    font-family: Lokem-Medium;
  }
  .tyt-heading-po {
    text-align: center;
  }
  .overview-col2-po {
    width: 100%;
    margin-left: 0;
  }
  .course-overview-image1-po {
    float: initial;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
  .course-overview-image2-po {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    float: initial;
    margin-top: 20px;
  }
  .tyt-heading-text-po{
    font-size: 30px;
    font-family: Lokem-Medium;
  }
  .tobj-heading-po {
    text-align: center !important;
  }
  .tobj-row-po {
    display: initial;
    width: 100%;
    padding-bottom: 40px;
    text-align: center;
  }
  .tobj-col1-po {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }
  .tyt-sub-heading-po {
    font-size: 22px;
  }
  .fac--po {
    color: #efbd20;
    padding-top: 60px;
    padding-bottom: 10px;
    font-family: Lokem-Medium;
  }
  .training-dates-main-po {
    background: url(../images/our3cBG.png),
      linear-gradient(to right, #ebd9a5, #e4cd8d);
    background-position: right;
    /*background: #fcdf8f;*/
    width: auto;
    text-align: center;
    border-radius: 10px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 35px;
    margin-top: 15px;
  }
  .course-overview-image2-po {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-top: 20px;
  }   
}
  }

