.course-confirmation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px; /* Add some padding */
  background: url(/assets/images/confirmation-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box; /* Ensure padding doesn't affect the height */
  color: white;
}

.course-confirm-message {
  font-size: 35px;
  letter-spacing: 1.5px;
  line-height: 1.5; /* Make the text easier to read */
  max-width: 80%; /* Limit the width of the message to avoid it stretching too much on wide screens */
  word-wrap: break-word; /* Prevent text overflow and ensure wrapping */

  /* On smaller screens, reduce the font size */
  @media (max-width: 600px) {
    font-size: 18px; /* Smaller font size for mobile */
    padding: 10px;
    max-width: 95%; /* Allow the message to take up more space on smaller screens */
  }
}

@media (max-width: 768px) {
  .course-confirmation-wrapper {
    padding: 15px; /* Add less padding on tablets */
    min-height: 300px; /* Ensure the wrapper takes full screen height */
  }

  .course-confirm-message {
    font-size: 20px; /* Slightly smaller font size for tablets */
    max-width: 90%; /* Ensure the text doesn't stretch too much */
  }
}
