.coupon-container {
    background-color: #f5f5f5; 
    min-height: 100vh;

    .title-bar {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px 23px;
      margin-bottom: 20px;
      font-family: 'Lokem-Medium';
  
      .menu-icon {
        font-size: 24px;
        margin-right: 10px;
        cursor: pointer;
        margin-left: 55px;

        i{
            font-size: 35px;
        }
      }
  
      h1 {
        font-size: 24px;
        font-family: 'Lokem-Medium';
        color: #000;
        margin: 0;
        margin-left: 5px;
      }
    }
  
    .coupon-table {
      width: 75%;
      border-collapse: collapse;
      background-color: transparent; 
      overflow: hidden;
      text-align: center;
      margin-left: 0;
      font-family: 'Lokem-Medium';
      margin-left: 80px;
      
  
       th {
            background-color: transparent; 
            color: #000;
            padding: 15px;
            text-align: left;
            border: 1px solid #000;
            text-align: center;
        }
  
        td {
            padding: 15px;
            border: 1px solid #000;
            color: #000;
            text-align: left;
        }
  
      .edit-btn,
      .delete-btn {
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 25px;
            color: #000; 
        }
    }

    @media (max-width: 768px) {
        .title-bar {
            .menu-icon {
                font-size: 18px !important;
            }

            h1 {
                font-size: 20px !important;
            }
        }
    }
}