@font-face {
  font-family: "SofiaProUltraLight"; /* Name to be used in CSS */
  src: url("../fonts/Sofia_Pro_UltraLight.ttf") format("truetype"); /* Path to your font file and format */
  font-style: normal; /* Normal style */
}
@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.contact-us {
  font-family: Lokem-Headings !important;
  width: 100%;
  .left-and-right {
    display: inline-flex;
    width: 100%;
  }
  .left-main {
    width: 42%;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .total-left {
    display: inline-block;
    width: 86%;
    margin-right: 2%;
    margin-left: 2%;
  }
  .heading-left {
    font-size: 35px;
    text-align: left;
    padding-bottom: 20px;
    font-family: Lokem-Medium;
  }
  .left-para {
    text-align: left;
    font-size: 18px;
    padding-bottom: 34px;
    font-family: Lokem-Lighter;
    padding-top: 0;
    line-height: 28px;
  }
  .phone-text {
    padding-bottom: 12px;
  }
  .left-space {
    margin-left: 10px;
    font-family: Lokem-Headings;
    font-size: 16px;
  }
  .mail-phone-main {
    width: 92%;
    margin-right: 2%;
    text-align: left;
    margin-left: 6%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .left-space {
    margin-left: 10px;
    font-family: Lokem-Headings;
    font-size: 16px;
  }
  .icon-style {
    color: #c9a328;
    font-weight: bold;
    font-size: 20px;
  }
  .contact-right {
    width: 40%;
    background-position: right;
    padding-left: 4%;
    padding-right: 6%;
    padding-top: 30px;
    padding-bottom: 30px;
    background: url("../images/our3cBG.png")
      linear-gradient(to right, #ebd9a5, #e4cd8d);
    background-color: #e9d69e; /* Fallback background color */
  }
  .drop-msg-text {
    width: 100%;
    display: inline-block;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 20px;
    font-size: 30px;
    font-family: "Lokem-Lighter";
  }
  #contact-form div {
    margin-bottom: 16px;
    text-align: left;
  }

  #contact-form input[type="text"],
  #contact-form select,
  #contact-form textarea {
    width: 100%;
    height: 40px;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    padding-left: 6px;
    border-radius: 10px;
    vertical-align: middle;
  }

  #contact-form input[type="button"] {
    background-color: #c9a328;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: Lokem-Lighter;
    font-size: 18px;
    margin-top: 15px;
  }

  #contact-form input[type="button"]:hover {
    background-color: #c9a328;
  }
  .lokem-button {
    background-color: #c9a328;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Lokem-Lighter;
    font-size: 18px;
    margin-top: 15px;
  }
 
  .train-your-main {
    width: 84%;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 1200px;
    margin: auto;
    text-align: center;
  }

  /* Banner section */
  /* Form section below the banner */
  .form-section {
    padding: 1rem;
    background-color: #f9f9f9; /* Background color for the form section */
    display: flex;
    justify-content: center; /* Center form section horizontally */
    padding-top: 1rem; /* Add space below the banner */
  }

  .text-content {
    flex: 1;
    padding-right: 2rem; /* Space between text and form */
    color: #333; /* Text color */
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  .form-container form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }

  .form-container {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
      font-weight: 900;
      font-family: lokem-Headings;
    }

    input,
    textarea,
    select {
      font-family: Lokem-Medium;
      font-size: 15px;
      opacity: 0.7;

      padding: 0.75rem;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: #fff;
      color: black;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    select {
      background-color: #fff;
    }
  }

  //  Contact Card Section //
  .contact-card {
    padding: 20px;
    max-width: 450px;
    margin: auto;
    text-align: left;
    margin-top: 2rem;

    h1 {
      font-size: 2em;
      font-weight: bolder;
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-family: Lokem-Medium;
    }

    p {
      font-size: 1.2em;
      line-height: 1.6;
      margin-bottom: 20px;
      color: #000;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .contact-item {
      display: flex;
      align-items: center;
    }

    .contact-icon {
      margin-right: 20px;
      color: #c9a328;
      font-size: 1em;
    }

    .contact-link {
      font-size: 1em;
      font-weight: bold;
      text-decoration: none;
      color: black;
      font-family: Lokem-Headings;
      &:hover {
        cursor: pointer;
      }
    }
  }

  // ERROR MESSAGE //
  .error {
    color: red;
    font-size: 14px;
    font-family:Lokem-Medium;
  }

  .fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Media query for mobile devices */
  @media (max-width: 995px) {
    .train-your-main {
      width: 94% !important;
      padding-left: 3% !important;
      padding-right: 3% !important;
      padding-bottom: 60px;
      padding-top: 20px;
      text-align: center;

      max-width: 1200px;
      margin: auto;
    }
    .left-and-right {
      display: inline-block;
      width: 100%;
    }

    .left-main {
      width: 92%;
      margin-left: 4%;
      margin-right: 4%;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .contact-right {
      width: 90%;
      padding-left: 4%;
      padding-right: 6%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 10px;
    }
    #contact-form div {
      margin-bottom: 16px;
      text-align: center;
    }
  }
}
