:root {
  --banner-img: "";
  --banner-img-position: "";
}

@font-face {
  font-family: "Lokem-Headings";
  src: url("../fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Lighter";
  src: url("../fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lokem-Medium";
  src: url("../fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("../fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}

//   /* Banner section */
.page-banner {
  width: 100%;
  min-height: 450px;
  /* Adjust the height of the banner */
  background: var(--banner-img) var(--banner-img-position);
  //background: url("../images/aboutus-banner.png") no-repeat center center;
  background-size: cover;
  /* Ensure the image covers the entire element */
  display: flex;
  justify-content: center;
  /* Center overlay horizontally */
  align-items: center;
  /* Center overlay vertically */
  position: relative;
  color: white;
  /* Text color */
  text-align: center;

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lokem-headers {
    font-family: "Lokem-Lighter";
    font-size: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 1px;
  }
  .banner-subtitle {
    margin-top: 30px;
    margin-bottom: 0px;
    font-family: Lokem-Medium;
    font-size: 20px;
  }
  @media (max-width: 995px) {
    .lokem-headers {
      font-size: 40px;
      width: 100%;
      margin: auto;
    }
    .overlay {
      width: 100%;
    }
  }
}
