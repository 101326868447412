/* Global styles */
body,
html {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the wrapper takes full height */
}

.main-content {
  flex: 1; /* Ensures the content grows and takes up the available space */
}

/*body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'LokemFont';
  src: url('../fonts/Sofia_Pro_UltraLight.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LokemFont';
  src: url('../fonts/Sofia_Pro_Bold.ttf');
  font-weight: bold;
  font-style: bold;
}




/* Reset default margin/padding for all elements */
/* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: LokemFont, sans-serif;
}

.container {
  width: 100%;
  padding: 20px;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer{
  font-family: LokemFont, sans-serif;
}*/
